import { IDetailCardData } from 'components/common/model';
import { useTranslation } from 'react-i18next';

const DetailCard = ({ resData, type }: IDetailCardData) => {
	const { t } = useTranslation('l3System');

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{type === 'product' ? (
				<ul className='weblink-wrapper'>
					<li>
						<span className='link-head'>{t('PRODUCT_NAME')}</span>
						<span className='link-separator'>:</span>&nbsp;
						<span className='link-data'>{resData?.productName}</span>
					</li>
					<li>
						<span className='link-head'>{t('GTIN')}</span>
						<span className='link-separator'>:</span>&nbsp;
						<span className='link-data'>{resData?.gtinNo}</span>
					</li>
					<li>
						<span className='link-head'>{t('INTERNAL_MATERIAL_NUMBER')}</span>
						<span className='link-separator'>:</span>&nbsp;
						<span className='link-data'>
							{resData?.iMaterialNumber ? resData?.iMaterialNumber : 'N/A'}
						</span>
					</li>
					<li>
						<span className='link-head'>{t('PRODUCT_CODE')}</span>
						<span className='link-separator'>:</span>&nbsp;
						<span className='link-data'>{resData?.productCode}</span>
					</li>
					<li>
						<span className='link-head'>{t('PRODUCT_MANUFACTURER')}</span>
						<span className='link-separator'>:</span>&nbsp;
						<span className='link-data'>{resData?.manufacturerName}</span>
					</li>
				</ul>
			) : (
				<ul className='weblink-wrapper'>
					<li>
						<span className='link-head'>{t('PRODUCT_ORDER')}</span>
						<span className='link-separator'>:</span>&nbsp;
						<span className='link-data'>{resData?.productionOrder}</span>
					</li>
					<li>
						<span className='link-head'>{t('PRODUCT_NAME')}</span>
						<span className='link-separator'>:</span>&nbsp;
						<span className='link-data'>{resData?.productName}</span>
					</li>
					<li>
						<span className='link-head'>{t('GTIN')}</span>
						<span className='link-separator'>:</span>&nbsp;
						<span className='link-data'>{resData?.gtinNo}</span>
					</li>
					{/* <li>
						<span className='link-head'>{t('INTERNAL_MATERIAL_NUMBER')}</span>
						<span className='link-separator'>:</span>&nbsp;
						<span className='link-data'>
							{resData?.iMaterialNumber ? resData?.iMaterialNumber : 'N/A'}
						</span>
					</li> */}
				</ul>
			)}
		</>
	);
};
export default DetailCard;
