import { useTranslation } from 'react-i18next';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';

import { useAppSelector } from 'hooks/stateHooks';
import { ICON_DESCRIPTION_ID } from 'utils/enum';
import { getExpanded } from 'slices/productmoduleSlice';

import CustomSwitch from 'components/common/button/CustomSwitch';
import CustomInput from 'components/common/forms/CustomInput';
import CustomTextArea from 'components/common/forms/CustomTextArea';
import ProductGeneralSkeleton from 'components/common/skeleton/ProductGeneralSkeleton';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import CustomLoader from 'components/common/loader/CustomLoader';
import ShowIcon from 'components/common/fontAwesome/ShowIcon';

import { getSanitizedHtml } from 'utils/utils';
import CustomSpan from '../addProducts/CustomSpan';
import ProductVersionGeneralFunctions from './ProductVersionGeneralFunctions';

const ProductVersionGeneral = () => {
	const { t } = useTranslation('productModule');
	const { t: tEndUserScan } = useTranslation('endUserScan');
	const expanded = useAppSelector(getExpanded);

	const {
		productName,
		editCommonAttributes,
		editGeneralAttributes,
		editIVedaAttributes,
		commonProductCode,
		isEndUserScanEnabled,
		productDescription,

		iVedaData,
		generalField,
		panelBarStatus,

		genericName,

		composition,

		strength,

		gs1CompanyPrefix,

		storageCondition,

		commonAttributesInfo,
		generalInfo,
		iVedaPropertitesInfo,

		eLeafletDownloadloading,
		eLeafletFileName,
		generalDetailsLoading,

		iVedaPropertiesStatus,

		manufacturerName,
		productGroupName,

		imageFileBase64,
		imageName,
		manufacturerLocation,
		internalMaterialNumber,
		isFieldActive,

		handleSelect,

		handleDownload,
		isIVedaPropertiesFieldActive,
		weblinkType,
		productNameSuperScript,
		isSerialNumberEnabled,
	} = ProductVersionGeneralFunctions();
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{generalDetailsLoading ? (
				<ProductGeneralSkeleton />
			) : (
				<div className='form-body position-relative'>
					<PanelBar
						isControlled
						expanded={expanded}
						expandMode='multiple'
						onSelect={handleSelect}>
						<PanelBarItem
							title={
								<div id='commonAttrHeading'>
									<h4 className='form-section-title mb-3 mt-3 info-title'>
										{t('COMMON_ATTRIBUTES')}
										<CustomEditableTooltip
											showIcon='faInfoCircle'
											text={commonAttributesInfo}
											moduleId={ICON_DESCRIPTION_ID.COMMON_ATTRIBUTES}
										/>
									</h4>
								</div>
							}>
							<div className='section-wrapper'>
								<div className='row'>
									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<CustomInput
												disabled
												className=''
												value={productName}
												label={t('PRODUCT_NAME')!!}
												onChange={() => {}}
												name='productName'
												placeholder={t('PRODUCT_NAME')}
												required
												type='text'
												isMandatory
											/>
										</div>
									</div>
									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<label className='form-label'>
												{t('PRODUCT_NAME_WITH_SUPERSCRIPT')}
											</label>
											<div className='text-input-area with-addBtn disabled'>
												<span
													className='input-inner'
													// eslint-disable-next-line react/no-danger
													dangerouslySetInnerHTML={{
														__html: getSanitizedHtml(
															productNameSuperScript,
														),
													}}
												/>
											</div>
										</div>
									</div>

									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<CustomTextArea
												disabled
												className=' two-row-height'
												value={productDescription}
												onChange={() => {}}
												label={t('PRODUCT_DESCRIPTION')!!}
												placeholder={t('PRODUCT_DESCRIPTION')!!}
												textAreaName='productDescription'
												textAreaId='productDescription'
												required
												cols={30}
												rows={5}
												isMandatory
											/>
										</div>
									</div>
									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<CustomInput
												disabled
												className=''
												value={commonProductCode}
												label={t('PRODUCT_CODE')!!}
												onChange={() => {}}
												name='commonProductCode'
												placeholder={t('PRODUCT_CODE')!!}
												required
												type='text'
												isMandatory
											/>
										</div>
										<div className='form-group'>
											{/* <CustomInput
												disabled
												className=''
												value={internalMaterialNumber}
												label={t('INTERNAL_MATERIAL_NUMBER_IMN')!!}
												onChange={() => {}}
												name='internalMaterialNumber'
												placeholder={t('INTERNAL_MATERIAL_NUMBER_IMN')!!}
												required
												type='text'
											/> */}
										</div>
									</div>

									<div className='col-xl-6 col-lg-6'>
										{/* <div className='form-group'>
											<div
												className={`form-control-switch-btn me-0 d-flex justify-content-between align-items-center ${
													editCommonAttributes ? 'cursor-not-allowed' : ''
												}`}>
												<label className='form-label mb-0'>
													{t('IS_ENDUSER_SCAN_ENABLED')!!}
												</label>
												<CustomSwitch
													disabled
													checked={isEndUserScanEnabled}
													name='isEndUserScanEnabled'
													handleChange={() => {}}
													onLabel=''
													offLabel=''
												/>
											</div>
										</div> */}
										<div className='form-group'>
											<CustomInput
												disabled
												className=''
												value={manufacturerName}
												label={t('MANUFACTURER')!!}
												onChange={() => {}}
												name='manufacturerName'
												placeholder={t('MANUFACTURER')!!}
												required
												type='text'
												isMandatory
											/>
										</div>
									</div>

									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<CustomInput
												disabled
												className=''
												value={productGroupName}
												label={t('PRODUCT_GROUP')!!}
												onChange={() => {}}
												name='productGroup'
												placeholder={t('PRODUCT_GROUP')!!}
												required
												type='text'
												isMandatory
											/>
										</div>
									</div>
									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<div
												className={`form-control-switch-btn me-0 d-flex justify-content-between align-items-center ${
													editCommonAttributes ? 'cursor-not-allowed' : ''
												}`}>
												{/* <label className='form-label mb-0'>
													{t('IS_SERIAL_NUMBER_ENABLED')!!}
												</label>
												<CustomSwitch
													disabled
													checked={isSerialNumberEnabled}
													name='isSerialNumberEnabled'
													handleChange={() => {}}
													onLabel=''
													offLabel=''
												/> */}
											</div>
										</div>
									</div>
									{/* <div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<CustomInput
												disabled
												className=''
												value={weblinkType}
												label={t('WEBLINK_TYPE')!!}
												onChange={() => {}}
												name='weblinkType'
												placeholder={t('WEBLINK_TYPE')!!}
												required
												type='text'
												isMandatory
											/>
										</div>
									</div> */}
									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<div
												className={`form-control-switch-btn me-0 d-flex justify-content-between align-items-center ${
													editCommonAttributes ? 'cursor-not-allowed' : ''
												}`}>
												<label className='form-label mb-0'>
													{t('IS_ENDUSER_SCAN_ENABLED')!!}
												</label>
												<CustomSwitch
													disabled
													checked={isEndUserScanEnabled}
													name='isEndUserScanEnabled'
													handleChange={() => {}}
													onLabel=''
													offLabel=''
												/>
											</div>
										</div>
									</div>
									{!panelBarStatus && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<label className='form-label'>
													{t('E_LEAFLET')!!}
												</label>
												<div className='upload-leaf-wrapper product-version my-2'>
													<span className='fileName-wrapper'>
														<span className='fileName'>
															{eLeafletFileName}
														</span>
													</span>
												</div>
												{eLeafletFileName ? (
													<span className='default-download-product-file my-2'>
														{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/jsx-no-comment-textnodes */}
														<span
															className='download-file-wrap'
															onClick={() => handleDownload()}>
															{eLeafletDownloadloading ? (
																<span className='loader-primary'>
																	<CustomLoader />
																</span>
															) : (
																<>
																	<span className='download-lbl-wrap'>
																		<span>
																			<ShowIcon name='faFilePdf' />
																		</span>
																		<span className='mfg-value-lbl'>
																			{tEndUserScan(
																				'DOWNLOAD_E_LEAFLET',
																			)}
																		</span>
																	</span>

																	<span>
																		<ShowIcon name='faAngleRight' />
																	</span>
																</>
															)}
														</span>
													</span>
												) : (
													<div className='text-center my-2 no-data-text'>
														{t('NO_FILE_FOUND')!!}
													</div>
												)}
											</div>
										</div>
									)}

									<div className='col-xl-6 col-lg-6 col-md-12'>
										<div className='form-group '>
											<label className='form-label'>
												{t('IMAGE_DETAILS')}
											</label>
											{imageFileBase64 && imageFileBase64 !== null ? (
												<div className='image-wrapper image-section border-0'>
													<img
														className='crop-image'
														src={imageFileBase64}
														alt=''
													/>
												</div>
											) : (
												// eslint-disable-next-line react/jsx-no-useless-fragment
												<></>
											)}
											<div className='text-center'>
												<span className='not-found-text'>
													&nbsp;{imageName || t('NO_FILE_FOUND')}
												</span>
											</div>
										</div>
									</div>
									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<CustomInput
												disabled
												className=''
												value={weblinkType}
												label={t('WEBLINK_TYPE')!!}
												onChange={() => {}}
												name='weblinkType'
												placeholder={t('WEBLINK_TYPE')!!}
												required
												type='text'
												isMandatory
											/>
										</div>
									</div>
									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<label className='form-label'>
												{t('MANUFACTURER_LOCATIONS')!!}
											</label>
											<span>*</span>
											<div className='chipset-disabled-wrapper'>
												{manufacturerLocation &&
													manufacturerLocation?.map(
														(location: string, index: number) => (
															<CustomSpan
																// eslint-disable-next-line react/no-array-index-key
																key={index}
																manufacturerLocationName={location}
															/>
														),
													)}
											</div>
										</div>
									</div>

									{/* <div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<label className='form-label'>
												{t('MANUFACTURER_LOCATIONS')!!}
											</label>
											<span>*</span>
											<div className='chipset-disabled-wrapper'>
												{manufacturerLocation &&
													manufacturerLocation?.map(
														(location: string, index: number) => (
															<CustomSpan
																// eslint-disable-next-line react/no-array-index-key
																key={index}
																manufacturerLocationName={location}
															/>
														),
													)}
											</div>
										</div>
									</div> */}
								</div>
							</div>
						</PanelBarItem>
						<PanelBarItem
							disabled={panelBarStatus}
							title={
								<div id='genaralHeading'>
									<h4 className='form-section-title mb-3 mt-3 info-title'>
										{t('GENERAL')}
										<CustomEditableTooltip
											showIcon='faInfoCircle'
											text={generalInfo}
											moduleId={ICON_DESCRIPTION_ID.GENERAL}
										/>
									</h4>
								</div>
							}>
							<div className='section-wrapper'>
								<div className='row'>
									{isFieldActive(1) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={gs1CompanyPrefix}
													onChange={() => {}}
													name='gs1CompanyPrefix'
													label={t('GS1_COMPANY_PREFIX')!!}
													placeholder={t('GS1_COMPANY_PREFIX')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(2) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.productSecName}
													onChange={() => {}}
													name='productSecName'
													label={t('PRODUCT_NAME')!!}
													placeholder={t('PRODUCT_NAME')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}

									{isFieldActive(3) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.unitPrice}
													onChange={() => {}}
													name='unitPrice'
													label={t('UNIT_PRICE')!!}
													placeholder={t('UNIT_PRICE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(4) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={genericName}
													onChange={() => {}}
													name='genericName'
													label={t('GENERIC_NAME')!!}
													placeholder={t('GENERIC_NAME')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(5) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.dosage}
													onChange={() => {}}
													name='dosage'
													label={t('DOSAGE')!!}
													placeholder={t('DOSAGE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(6) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled={editGeneralAttributes}
													value={strength}
													onChange={() => {}}
													name='strength'
													label={t('STRENGTH')!!}
													placeholder={t('STRENGTH')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(7) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled={editGeneralAttributes}
													value={generalField?.formType}
													onChange={() => {}}
													name='formType'
													label={t('FORM_TYPE')!!}
													placeholder={t('FORM_TYPE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(8) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.packType}
													onChange={() => {}}
													name='packType'
													label={t('PACK_TYPE')!!}
													placeholder={t('PACK_TYPE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(9) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={storageCondition}
													onChange={() => {}}
													name='storageCondition'
													label={t('STORAGE_CONDITION')!!}
													placeholder={t('STORAGE_CONDITION')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(10) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.revisionNumber}
													onChange={() => {}}
													name='revisionNumber'
													label={t('REVISION_NUMBER')!!}
													placeholder={t('REVISION_NUMBER')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(11) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.licenseNumber}
													onChange={() => {}}
													name='licenseNumber'
													label={t('LICENSE_NUMBER')!!}
													placeholder={t('LICENSE_NUMBER')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(12) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.weight}
													onChange={() => {}}
													name='weight'
													label={t('WEIGHT')!!}
													placeholder={t('WEIGHT')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(31) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.ai710GermanyIFA}
													onChange={() => {}}
													name='ai710GermanyIFA'
													label={t('AI_710_GERMANY_IFA')!!}
													placeholder={t('AI_710_GERMANY_IFA')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(32) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.ai711FranceCIP}
													onChange={() => {}}
													name='ai711FranceCIP'
													label={t('AI_711_FRANCE_CIP')!!}
													placeholder={t('AI_711_FRANCE_CIP')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(33) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.ai712SpainNationalCode}
													onChange={() => {}}
													name='ai712SpainNationalCode'
													label={t('AI_712_SPAIN_NATIONAL_CODE')!!}
													placeholder={t('AI_712_SPAIN_NATIONAL_CODE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(34) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.atPZN}
													onChange={() => {}}
													name='atPZN'
													label={t('AT_PZN')!!}
													placeholder={t('AT_PZN')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}

									{isFieldActive(35) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													value={generalField?.beABPCode}
													disabled={editGeneralAttributes}
													onChange={() => {}}
													name='beABPCode'
													label={t('BE_ABP_CODE')!!}
													placeholder={t('BE_ABP_CODE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}

									{isFieldActive(36) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.brAnvisaRegistrationNumber}
													onChange={() => {}}
													name='brAnvisaRegistrationNumber'
													label={t('BR_ANVISA_REGISTRATION_NUMBER')!!}
													placeholder={
														t('BR_ANVISA_REGISTRATION_NUMBER')!!
													}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(37) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.caDIN}
													onChange={() => {}}
													name='caDIN'
													label={t('CA_DIN')!!}
													placeholder={t('CA_DIN')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(38) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.chSwissmedic}
													onChange={() => {}}
													name='chSwissmedic'
													label={t('CH_SWISSMEDIC')!!}
													placeholder={t('CH_SWISSMEDIC')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(39) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.cnSubtypeCode}
													onChange={() => {}}
													name='cnSubtypeCode'
													label={t('CN_SUBTYPE_CODE')!!}
													placeholder={t('CN_SUBTYPE_CODE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(40) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.dePPN}
													onChange={() => {}}
													name='dePPN'
													label={t('DE_PPN')!!}
													placeholder={t('DE_PPN')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(41) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.dePZN}
													onChange={() => {}}
													name='dePZN'
													label={t('DE_PZN')!!}
													placeholder={t('DE_PZN')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(14) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.ean13}
													onChange={() => {}}
													name='ean13'
													label={t('EAN_13')!!}
													placeholder={t('EAN_13')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(42) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.grEOFCode}
													onChange={() => {}}
													name='grEOFCode'
													label={t('GR_EOF_CODE')!!}
													placeholder={t('GR_EOF_CODE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(43) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.hrCroatiaNationalCode}
													onChange={() => {}}
													name='hrCroatiaNationalCode'
													label={t('HR_CROATIA_NATIONAL_CODE')!!}
													placeholder={t('HR_CROATIA_NATIONAL_CODE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(44) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.inProductCode}
													onChange={() => {}}
													name='inProductCode'
													label={t('IN_PRODUCT_CODE')!!}
													placeholder={t('IN_PRODUCT_CODE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(15) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.internalMaterialNumber}
													onChange={() => {}}
													name='internalMaterialNumber'
													label={t('INTERNAL_MATERIAL_NUMBER')!!}
													placeholder={t('INTERNAL_MATERIAL_NUMBER')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(45) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.itBollino}
													onChange={() => {}}
													name='itBollino'
													label={t('IT_BOLINO')!!}
													placeholder={t('IT_BOLINO')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(46) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.krKFDACode}
													onChange={() => {}}
													name='krKFDACode'
													label={t('KR_KFDA_CODE')!!}
													placeholder={t('KR_KFDA_CODE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(47) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.nlKNMP}
													onChange={() => {}}
													name='nlKNMP'
													label={t('NL_KNMP')!!}
													placeholder={t('NL_KNMP')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(48) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.nrdNordicVNRDrugCode}
													onChange={() => {}}
													name='nrdNordicVNRDrugCode'
													label={t('NRD_NORDIC_VNR_DRUG_CODE')!!}
													placeholder={t('NRD_NORDIC_VNR_DRUG_CODE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(49) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled={editGeneralAttributes}
													value={generalField?.ptAIMNumber}
													onChange={() => {}}
													name='ptAIMNumber'
													label={t('PT_AIM_NUMBER')!!}
													placeholder={t('PT_AIM_NUMBER')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(50) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.saSaudiDrugCode}
													onChange={() => {}}
													name='saSaudiDrugCode'
													label={t('SA_SAUDI_DRUG_CODE')!!}
													placeholder={t('SA_SAUDI_DRUG_CODE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(51) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled={editGeneralAttributes}
													value={generalField?.usNDC442}
													onChange={() => {}}
													name='usNDC442'
													label={t('US_NDC442')!!}
													placeholder={t('US_NDC442')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(52) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.usNDC532}
													onChange={() => {}}
													name='usNDC532'
													label={t('US_NDC532')!!}
													placeholder={t('US_NDC532')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(53) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.usNDC541}
													onChange={() => {}}
													name='usNDC541'
													label={t('US_NDC541')!!}
													placeholder={t('US_NDC541')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(54) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.usNDC542}
													onChange={() => {}}
													name='usNDC542'
													label={t('US_NDC542')!!}
													placeholder={t('US_NDC542')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(55) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.ai714PortugalAIM}
													onChange={() => {}}
													name='ai714PortugalAIM'
													label={t('AI_714_PORTUGAL_AIM')!!}
													placeholder={t('AI_714_PORTUGAL_AIM')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(16) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<div className='form-control-switch-btn me-0 d-flex justify-content-between align-items-center'>
													<label className='form-label mb-0'>
														{
															t(
																'AI_27_GOVERNMENT_PRODUCT_NOT_FOR_SALE',
															)!!
														}
													</label>
													<CustomSwitch
														disabled
														checked={
															generalField?.ai27ProductNotforSale
														}
														className='ms-2'
														name='ai27ProductNotforSale'
														handleChange={() => {}}
														onLabel=''
														offLabel=''
													/>
												</div>
											</div>
										</div>
									)}
									{isFieldActive(17) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled={editGeneralAttributes}
													value={generalField?.tNVED4}
													onChange={() => {}}
													name='tNVED4'
													label={t('TNVED_4')!!}
													placeholder={t('TNVED_4')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(56) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.vat}
													onChange={() => {}}
													name='vat'
													label={t('VAT')!!}
													placeholder={t('VAT')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(57) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.siNTINCode}
													onChange={() => {}}
													name='siNTINCode'
													label={t('SI_NTIN_CODE')!!}
													placeholder={t('SI_NTIN_CODE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}

									{isFieldActive(58) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.fdaNDC11}
													onChange={() => {}}
													name='fdaNDC11'
													label={t('FDA_NDC_11')!!}
													placeholder={t('FDA_NDC_11')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(59) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.article57Code}
													onChange={() => {}}
													name='article57Code'
													label={t('ARTICLE_57_CODE')!!}
													placeholder={t('ARTICLE_57_CODE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(60) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													value={generalField?.treacebilityCodeName}
													disabled={editGeneralAttributes}
													onChange={() => {}}
													name='treacebilityCodeName'
													label={t('TREACEABILITY_CODE_NAME')!!}
													placeholder={t('TREACEABILITY_CODE_NAME')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(19) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled={editGeneralAttributes}
													value={generalField?.barcode}
													onChange={() => {}}
													name='barcode'
													label={t('BARCODE')!!}
													placeholder={t('BARCODE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(20) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled={editGeneralAttributes}
													value={generalField?.digitalCode}
													onChange={() => {}}
													name='digitalCode'
													label={t('DIGITAL_CODE')!!}
													placeholder={t('DIGITAL_CODE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(21) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-control-switch-btn me-0 d-flex justify-content-between align-items-center'>
												<label className='form-label mb-0'>
													{t('SCAN_CODE_PROMPT')!!}
												</label>
												<CustomSwitch
													disabled
													checked={generalField?.scanCodePrompt}
													name='scanCodePrompt'
													handleChange={() => {}}
													className='ms-2'
													onLabel=''
													offLabel=''
												/>
											</div>
										</div>
									)}
									{isFieldActive(22) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.callEnquiries}
													onChange={() => {}}
													name='callEnquiries'
													label={t('CALL_ENQUIRIES')!!}
													placeholder={t('CALL_ENQUIRIES')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(23) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={composition}
													onChange={() => {}}
													name='composition'
													label={t('COMPOSITION')!!}
													placeholder={t('COMPOSITION')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(61) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.ukNHRN}
													onChange={() => {}}
													name='ukNHRN'
													label={t('UK_NHRN')!!}
													placeholder={t('UK_NHRN')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(62) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.cnProductCode}
													onChange={() => {}}
													name='cnProductCode'
													label={t('CN_PRODUCT_CODE')!!}
													placeholder={t('CN_PRODUCT_CODE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(63) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.ai713BrazilAnvisa}
													onChange={() => {}}
													name='ai713BrazilAnvisa'
													label={t('AI_713_BRAZIL_ANVISA')!!}
													placeholder={t('AI_713_BRAZIL_ANVISA')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(64) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.cnPackagespec}
													onChange={() => {}}
													name='cnPackagespec'
													label={t('CN_PACKAGESPEC')!!}
													placeholder={t('CN_PACKAGESPEC')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(24) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.grossWeight}
													onChange={() => {}}
													name='grossWeight'
													label={t('GROSS_WEIGHT')!!}
													placeholder={t('GROSS_WEIGHT')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(25) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.netWeight}
													onChange={() => {}}
													name='netWeight'
													label={t('NET_WEIGHT')!!}
													placeholder={t('NET_WEIGHT')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(26) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.unitProductDimension}
													onChange={() => {}}
													name='unitProductDimension'
													label={t('UNIT_PRODUCT_DIMENSIONS')!!}
													placeholder={t('UNIT_PRODUCT_DIMENSIONS')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(65) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.usNDCPharma}
													onChange={() => {}}
													name='usNDCPharma'
													label={t('US_NDC_PHARMA')!!}
													placeholder={t('US_NDC_PHARMA')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(88) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.brandName}
													onChange={() => {}}
													name='brandName'
													label={t('BRAND_NAME')!!}
													placeholder={t('BRAND_NAME')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(27) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.shelfLifeInDays}
													onChange={() => {}}
													name='shelfLifeInDays'
													label={t('SHELF_LIFE_IN_DAYS')!!}
													placeholder={t('SHELF_LIFE_IN_DAYS')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(28) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.batchSize}
													onChange={() => {}}
													name='batchSize'
													label={t('BATCH_SIZE')!!}
													placeholder={t('BATCH_SIZE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(29) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.fgCode}
													onChange={() => {}}
													name='fgCode'
													label={t('FG_CODE')!!}
													placeholder={t('FG_CODE')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(30) && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={generalField?.marketRegion}
													onChange={() => {}}
													name='marketRegion'
													label={t('MARKET_REGION')!!}
													placeholder={t('MARKET_REGION')!!}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(90) && (
										<div className='col-xl-12'>
											<div className='form-group'>
												<CustomTextArea
													disabled={editGeneralAttributes}
													value={generalField?.marketedBy}
													textAreaName='marketedBy'
													label={t('MARKETED_BY')!!}
													onChange={() => {}}
													className='two-row-height'
													placeholder={t('MARKETED_BY')!!}
													textAreaId='marketedBy'
													required
													cols={30}
													rows={5}
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(13) && (
										<div className='col-xl-12'>
											<div className='form-group'>
												<CustomTextArea
													disabled
													value={generalField?.ai240AdditionalItemInfo}
													textAreaName='ai240AdditionalItemInfo'
													label={t('AI_240_ADDITIONAL_ITEM_INFO')!!}
													onChange={() => {}}
													className='two-row-height'
													placeholder={t('AI_240_ADDITIONAL_ITEM_INFO')!!}
													textAreaId='ai240AdditionalItemInfo'
													required
													cols={30}
													rows={5}
													isMandatory
												/>
											</div>
										</div>
									)}
										{isFieldActive(91) && (
										<div className='col-xl-12'>
											<div className='form-group'>
												<CustomTextArea
													disabled={editGeneralAttributes}
													value={generalField?.Excipents}
													textAreaName='Excipents'
													label={t('Excipents')!!}
													onChange={() => {}}
													className='two-row-height'
													placeholder={t('Excipents')!!}
													textAreaId='Excipents'
													required
													cols={30}
													rows={5}
													isMandatory
												/>
											</div>
										</div>
									)}
									{isFieldActive(18) && (
										<div className='col-xl-12'>
											<div className='form-group'>
												<CustomTextArea
													disabled
													value={generalField?.netContentDescription}
													textAreaName='netContentDescription'
													label={t('NET_CONTENT_DESCRIPTION')!!}
													onChange={() => {}}
													placeholder={t('NET_CONTENT_DESCRIPTION')!!}
													className='two-row-height'
													textAreaId='netContentDescription'
													required
													cols={30}
													rows={5}
													isMandatory
												/>
											</div>
										</div>
									)}
								</div>
							</div>
						</PanelBarItem>
						{/**
						 * i veda properties
						 * fields
						 *  */}
						{iVedaPropertiesStatus && (
							<PanelBarItem
								disabled={panelBarStatus}
								title={
									<div id='ivedaHeading'>
										<h4 className='form-section-title mb-3 mt-3 info-title'>
											{t('I_VEDA_PROPERTIES')}
											<CustomEditableTooltip
												showIcon='faInfoCircle'
												text={iVedaPropertitesInfo}
												moduleId={ICON_DESCRIPTION_ID.I_VEDA_PROPERTIES}
											/>
										</h4>
									</div>
								}>
								<div className='section-wrapper'>
									<div className='row'>
										{isIVedaPropertiesFieldActive(87) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled
														className=''
														value={iVedaData?.productNameIVeda}
														onChange={() => {}}
														label={t('PRODUCT_NAME')!!}
														name='productNameIVeda'
														placeholder={t('PRODUCT_NAME')}
														required
														type='text'

														// isMandatory
													/>
												</div>
											</div>
										)}
										{isIVedaPropertiesFieldActive(66) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editIVedaAttributes}
														className=''
														value={iVedaData?.productCodeIVeda}
														onChange={() => {}}
														label={t('PRODUCT_CODE')!!}
														name='productCodeIVeda'
														placeholder={t('PRODUCT_CODE')!!}
														required
														type='text'

														// isMandatory
													/>
												</div>
											</div>
										)}
										{isIVedaPropertiesFieldActive(67) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled
														className=''
														value={iVedaData?.hsCode}
														onChange={() => {}}
														label={t('HS_CODE')!!}
														name='hsCode'
														placeholder={t('HS_CODE')!!}
														required
														type='text'

														// isMandatory
													/>
												</div>
											</div>
										)}
										{isIVedaPropertiesFieldActive(68) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled
														className=''
														value={iVedaData?.productType}
														onChange={() => {}}
														label={t('PRODUCT_TYPE')!!}
														name='productType'
														placeholder={t('PRODUCT_TYPE')!!}
														required
														type='text'

														// isMandatory
													/>
												</div>
											</div>
										)}
										{/* change value */}
										{isIVedaPropertiesFieldActive(89) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled
														className=''
														value={iVedaData?.manufacturerCode}
														onChange={() => {}}
														label={t('MANUFACTURER_CODE')!!}
														name='manufacturerCode'
														placeholder={t('MANUFACTURER_CODE')!!}
														required
														type='text'

														// isMandatory
													/>
												</div>
											</div>
										)}
										{isIVedaPropertiesFieldActive(71) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled={editIVedaAttributes}
														className=''
														value={iVedaData?.manufacturingSiteCode}
														onChange={() => {}}
														label={t('MANUFACTURING_SITE_CODE')!!}
														name='manufacturingSiteCode'
														placeholder={t('MANUFACTURING_SITE_CODE')!!}
														required
														type='text'

														// isMandatory
													/>
												</div>
											</div>
										)}
										{isIVedaPropertiesFieldActive(70) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled
														className=''
														value={iVedaData?.schedule}
														onChange={() => {}}
														label={t('SCHEDULED')!!}
														name='hsCode'
														placeholder={t('SCHEDULED')!!}
														required
														type='text'

														// isMandatory
													/>
												</div>
											</div>
										)}
										{isIVedaPropertiesFieldActive(72) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled
														className=''
														value={iVedaData?.genericNameIVeda}
														onChange={() => {}}
														label={t('GENERIC_NAME')!!}
														name='genericNameIVeda'
														placeholder={t('GENERIC_NAME')!!}
														required
														type='text'

														// isMandatory
													/>
												</div>
											</div>
										)}
										{isIVedaPropertiesFieldActive(73) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled
														className=''
														value={iVedaData?.storageCondition}
														onChange={() => {}}
														label={t('STORAGE_CONDITION')!!}
														name='hsCode'
														placeholder={t('STORAGE_CONDITION')!!}
														required
														type='text'

														// isMandatory
													/>
												</div>
											</div>
										)}
										{isIVedaPropertiesFieldActive(74) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled
														className=''
														value={iVedaData?.primaryLevelGtin}
														onChange={() => {}}
														label={t('PRIMARY_LEVEL_GTIN')!!}
														name='primaryLevelGtin'
														placeholder={t('PRIMARY_LEVEL_GTIN')!!}
														required
														type='text'

														// isMandatory
													/>
												</div>
											</div>
										)}
										{isIVedaPropertiesFieldActive(75) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled
														className=''
														value={iVedaData?.secondaryLevelGtin1}
														onChange={() => {}}
														label={t('SECONDARY_LEVEL_GTIN_1')!!}
														name='secondaryLevelGtin1'
														placeholder={t('SECONDARY_LEVEL_GTIN_1')!!}
														required
														type='text'

														// isMandatory
													/>
												</div>
											</div>
										)}
										{isIVedaPropertiesFieldActive(76) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														className=''
														value={iVedaData?.secondaryLevelGtin2}
														onChange={() => {}}
														label={t('SECONDARY_LEVEL_GTIN_2')!!}
														name='secondaryLevelGtin2'
														placeholder={t('SECONDARY_LEVEL_GTIN_2')!!}
														required
														type='text'

														// isMandatory
													/>
												</div>
											</div>
										)}
										{isIVedaPropertiesFieldActive(77) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled
														className=''
														value={iVedaData?.secondaryLevelGtin3}
														onChange={() => {}}
														label={t('SECONDARY_LEVEL_GTIN_3')!!}
														name='secondaryLevelGtin3'
														placeholder={t('SECONDARY_LEVEL_GTIN_3')!!}
														required
														type='text'

														// isMandatory
													/>
												</div>
											</div>
										)}
										{isIVedaPropertiesFieldActive(78) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled
														className=''
														value={iVedaData?.tertiaryLevelGtin}
														onChange={() => {}}
														label={t('TERTIARY_LEVEL_GTIN')!!}
														name='tertiaryLevelGtin'
														placeholder={t('TERTIARY_LEVEL_GTIN')!!}
														required
														type='text'

														// isMandatory
													/>
												</div>
											</div>
										)}
										{isIVedaPropertiesFieldActive(80) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled
														className=''
														value={iVedaData?.strengthIveda}
														onChange={() => {}}
														label={t('STRENGTH')!!}
														name='strengthIveda'
														placeholder={t('STRENGTH')!!}
														required
														type='text'

														// isMandatory
													/>
												</div>
											</div>
										)}
										{isIVedaPropertiesFieldActive(81) && (
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														disabled
														className=''
														value={iVedaData?.dosageName}
														onChange={() => {}}
														label={t('DOSAGE')!!}
														name='hsCode'
														placeholder={t('DOSAGE')!!}
														required
														type='text'
													/>
												</div>
											</div>
										)}

										{isIVedaPropertiesFieldActive(79) && (
											<div className='col-xl-12'>
												<div className='form-group'>
													<CustomTextArea
														disabled
														className=' two-row-height'
														value={iVedaData?.compositionIVeda}
														onChange={() => {}}
														label={t('COMPOSITION')!!}
														placeholder={t('COMPOSITION')!!}
														textAreaName='compositionIVeda'
														textAreaId='compositionIVeda'
														required
														cols={30}
														rows={5}
													/>
												</div>
											</div>
										)}
										{isIVedaPropertiesFieldActive(69) && (
											<div className='col-xl-12'>
												<div className='form-group'>
													<CustomTextArea
														disabled
														className=' two-row-height'
														value={iVedaData?.remark}
														onChange={() => {}}
														label={t('REMARK')!!}
														placeholder={t('REMARK')!!}
														textAreaName='remark'
														textAreaId='remark'
														required
														cols={30}
														rows={5}
													/>
												</div>
											</div>
										)}
									</div>
								</div>
							</PanelBarItem>
						)}
					</PanelBar>
				</div>
			)}
		</>
	);
};

export default ProductVersionGeneral;
