import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getSanitizedHtml, utcFormat } from 'utils/utils';
// import { useAppSelector } from 'hooks/stateHooks';
// import { getDataFormat } from 'slices/configSlice';

import GridSkeleton from 'components/common/skeleton/GridSkeleton';

import ScanHistoryList from 'components/common/scanHistory/ScanHistoryList';
import { END_USER_TRANSLATION_KEY } from 'utils/constants';
// import EndUserOptionalFieldsSkeleton from 'components/common/skeleton/EndUserOptionalFieldsSkeleton';
import DownloadELeaflet from './DownloadELeaflet';

const ScannedProductDetails = ({
	productDetails,
	scanHistoryData,
	resultId,
	encProductId,
	scanHistoryLoading,
	fileName,
	statusFieldShow,
	productGroupFieldsDataRes,
	// productGroupFieldsDataLoading,
	encVersionId,
}: any) => {
	const { t } = useTranslation('endUserScan');
	const location = useLocation();
	// const getCurrentDate = useAppSelector(getDataFormat);

	const [urlUniqueProductId, setUrlUniqueProductId] = useState<string>('');
	// const [marketedBySplitedData, setMarketedBySplitedData] = useState<any>([]);
	const [manufacturerSplitedData, setManufacturerSplitedData] = useState<any>([]);

	useEffect(() => {
		/**
		 * URL slpits for get Unique product identification code
		 */
		const splitedUrl = location?.pathname?.split('/');
		/**
		 * Set Url Unique Product Id from url path name splitted
		 */
		setUrlUniqueProductId(splitedUrl[1]);
	}, [location]);

	/**
	 * using this funtion for if any fields have multiline values to display it splits and shows
	 * @param fieldValue passing field value
	 * @returns returns the splited data element
	 */

	const handleMultiLineSplit = (fieldValue) => {
		let splitedData;
		if (fieldValue) {
			splitedData = fieldValue?.split('\n');
		} else {
			return <span>N/A</span>;
		}
		return (
			splitedData &&
			splitedData?.map((data, i) => (
				// eslint-disable-next-line react/no-array-index-key
				<span key={i}>{data}</span>
			))
		);
	};

	//  commenting this because marketed By field already in Product group section

	// useEffect(() => {
	// 	let splitedData;
	// 	if (productDetails?.marketedBy) {
	// 		splitedData = productDetails?.marketedBy?.split('\n');
	// 		setMarketedBySplitedData(splitedData);
	// 	}
	// }, [productDetails?.marketedBy]);

	useEffect(() => {
		let splitedData;
		if (productDetails?.address) {
			splitedData = productDetails?.address?.split('\n');
			setManufacturerSplitedData(splitedData);
		}
	}, [productDetails?.address]);

	return (
		<>
			{statusFieldShow && (
				<span
					className={
						// eslint-disable-next-line no-nested-ternary
						productDetails?.customWeblinkStatus && productDetails?.productStatus
  											? 'flag-status'
  											: productDetails?.RetrivalTypeName === 'Accepted'
  											? 'sv-btn bg-success' // Green (Accepted)
  											: productDetails?.RetrivalTypeName === 'Sample' || 
   											 productDetails?.RetrivalTypeName === 'Recall' || 
    										productDetails?.RetrivalTypeName === 'Return' || 
    										productDetails?.RetrivalTypeName === 'Specimen'
  											? 'sv-btn bg-orange' // Orange (Sample, Recall, Return, Specimen)
  											: productDetails?.RetrivalTypeName === 'Damaged' || 
  											productDetails?.RetrivalTypeName === 'Destroyed' || 
   										    productDetails?.RetrivalTypeName === 'Quarantine'
                                            ? 'sv-btn bg-danger' // Red (Damaged, Destroyed, Quarantine)
  										    : ''
						 	
					}>
					{/**
					 * Showing genuine product label if there is no URL prefix in setings
					 * and the valid condition is true
					 *  */}
					{/* eslint-disable-next-line no-nested-ternary */}
					{/* {productDetails?.customWeblinkStatus && productDetails?.productStatus
						? t('GENUINE_PRODUCT_LABEL')
						: productDetails?.productStatus
						? t('VALID')
						: t('INVALID')} */}
					<div style={{ fontSize: '140%' }}> {productDetails?.RetrivalTypeName === 'Accepted'?'Valid': productDetails?.RetrivalTypeName} </div>
					
				</span>
			)}

			<h3>
				{/* {productDetails?.productName} */}

				{/* showing Product Name With Superscript */}
				<span
					className='product-super-script'
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: getSanitizedHtml(productDetails?.productNameSuperscript),
					}}
				/>
			</h3>
			{productDetails?.parentCompanyName && (
				<span className='company-name'>{productDetails?.parentCompanyName}</span>
			)}
			<p>{productDetails?.productDescription}</p>
			<div className='scanned-card-wrap'>
				<div className='row row-gutter-10px'>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('PRODUCT_CODE')}:`}</span>
							<span className='mfg-value-lbl'>
								{/**
								 * Showing urlUniqueProductId splitted from URL if there is no URL prefix in setings
								 * and only for NON GS1 condition (non gs1 checking in back-end)
								 *  */}
								{productDetails?.hideTenantPrefix
									? urlUniqueProductId
									: productDetails?.productCode || 'N/A'}
							</span>
						</div>
					</div>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('GENERIC_NAME')}:`}</span>
							<span className='mfg-value-lbl'>
								{productDetails?.genericName || 'N/A'}
							</span>
						</div>
					</div>
					<div className='col-md-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('MANUFACTURER_ADDRESS')}:`}</span>
							<span className='mfg-value-lbl'>
								{productDetails?.manufacturer}
								{productDetails?.manufacturer && <br />}
								{manufacturerSplitedData && manufacturerSplitedData.length > 0
									? manufacturerSplitedData?.map((data, i) => (
											// eslint-disable-next-line react/no-array-index-key
											<span key={i}>{data}</span>
									  ))
									: 'N/A'}
							</span>
						</div>
					</div>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('MFG_DATE')}:`}</span>
							<span className='mfg-value-lbl'>
								{productDetails?.manufacturingDate
									? utcFormat(
											productDetails.manufacturingDate,
											productDetails?.manufacturingAndExpiryDateFormat,
									  )?.toUpperCase()
									: 'N/A'}
							</span>
						</div>
					</div>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('EXP_DATE')}:`}</span>
							<span className='mfg-value-lbl'>
								{/* {productDetails?.expiryDate
									? utcFormat(
											productDetails.expiryDate,
											productDetails?.dateFormat
												? productDetails?.dateFormat
												: getCurrentDate,
									  )
									: 'N/A'} */}
								{productDetails?.expiryDate
									? utcFormat(
											productDetails.expiryDate,
											productDetails?.manufacturingAndExpiryDateFormat,
									  )?.toUpperCase()
									: 'N/A'}
							</span>
						</div>
					</div>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('LOT_NO')}:`}</span>
							<span className='mfg-value-lbl'>{productDetails?.lotNo || 'N/A'}</span>
						</div>
					</div>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('BRAND_NAME')}:`}</span>
							<span className='mfg-value-lbl'>
								{productDetails?.brandName || 'N/A'}
							</span>
						</div>
					</div>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('LICENSE_NO')}:`}</span>
							<span className='mfg-value-lbl'>
								{productDetails?.licenseNumber || 'N/A'}
							</span>
						</div>
					</div>
					{productDetails?.isSerialNumberEnabled && (
						<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
							<div className='scanned-products-card'>
								<span className='mfg-lbl'>{`${t('SERIAL_NO')}:`}</span>
								<span className='mfg-value-lbl'>
									{productDetails?.serialNumber || 'N/A'}
								</span>
							</div>
						</div>
					)}
					{/* Product Group fields section */}
					{/* {productGroupFieldsDataLoading && <EndUserOptionalFieldsSkeleton />} */}

					{/* sorting product group fields response data to show multiline fields to show firstly */}

					{productGroupFieldsDataRes &&
						productGroupFieldsDataRes
							?.sort((a, b) =>
								// eslint-disable-next-line no-nested-ternary
								a?.multiLine === b?.multiLine ? 0 : a?.multiLine ? -1 : 1,
							)
							?.map((item: any, i) => {
								return (
									<div
										className={`${
											item?.multiLine &&
											item?.fieldName === 'Details of Excipients'
												? 'col-lg-10 col-md-10 col-sm-12 gutter-10px mb-10px'
												: item.multiLine
												? ' col-lg-6 col-md-6 col-sm-12 gutter-10px mb-10px'
												: ' col-lg-3 col-md-6 col-6  col-sm-12 gutter-10px mb-10px '
										}`}
										// eslint-disable-next-line react/no-array-index-key
										key={i}>
										<div className='scanned-products-card'>
											<span className='mfg-lbl'>{`${item?.fieldName}: `}</span>
											<span className='mfg-value-lbl'>
												{/* checking muliline condition from API response to show data in multiline or not */}
												{item?.multiLine
													? handleMultiLineSplit(item?.fieldValue)
													: item?.fieldValue || 'N/A'}
											</span>
										</div>
									</div>
								);
							})}
					{/* commenting this because marketed By field already in Product group section */}
					{/* Showing marked by if there is any value in it
					{productDetails?.marketedBy && (
						<div className='col-lg-3 col-md-6  col-sm-12 gutter-10px mb-10px'>
							<div className='scanned-products-card'>
								<span className='mfg-lbl'>{`${t('MARKETED_BY')}:`}</span>
								<span className='mfg-value-lbl'>
									{marketedBySplitedData &&
										marketedBySplitedData?.map((data, i) => (
											// eslint-disable-next-line react/no-array-index-key
											<span key={i}>{data}</span>
										))}
								</span>
							</div>
						</div>
					)} */}
				</div>

				{resultId === 100 && encProductId !== null && fileName !== null && (
					<div className='row row-gutter-10px'>
						<div className='col-lg-6 col-md-6 col-sm-6 gutter-10px'>
							<div className='download-product-file ms-0'>
								<DownloadELeaflet
									encProductId={encProductId}
									productName={productDetails?.productName}
									versionId={encVersionId}
								/>
							</div>
						</div>
					</div>
				)}
			</div>

			{productDetails?.isEndUserScanEnabled && (
				<>
					<div className='scan-wrap'>
						<h4 className='scan-lbl'>{`${t('SCAN_HISTORY')}:`}</h4>
						<span className='scan-score'>
							{productDetails?.scanHistoryCount || 'N/A'}
						</span>
					</div>

					<div className='default-kendo-grid'>
						{scanHistoryLoading ? (
							<GridSkeleton />
						) : (
							<ScanHistoryList
								scanHistoryData={scanHistoryData}
								translationkey={END_USER_TRANSLATION_KEY}
							/>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default ScannedProductDetails;
