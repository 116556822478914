import { useState, useEffect, useCallback, useRef, createRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { productFormValidations } from 'utils/utils';
import { ICON_DESCRIPTION_ID } from 'utils/enum';
import { AUTO_SAVE, ERROR, MAX_FILE_SIZE } from 'utils/constants';
import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';
import {
	getELeafletFileName,
	getEncProductId,
	getIVedaPropertiesStatus,
	getProductSideBarIsActive,
	getProductSideBarIsSubmited,
	setELeafletFileName,
	setEncProductId,
	setExpanded,
	setIVedaPropertiesStatus,
	setManufacturerGs1Prefix,
	setProductSideBarIsActive,
	setProductSideBarIsSubmited,
	setProductSideBarValue,
} from 'slices/productmoduleSlice';
import { getProductAreaIconsData } from 'slices/infoIconsDataSlice';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

import { ChangeEvent } from '@progress/kendo-react-dropdowns/dist/npm/common/events';
import { PanelBarSelectEventArguments } from '@progress/kendo-react-layout';

import getAllProductGroupNameService from '../services/getAllProductGroupNameService';
import getAllProductAttributesByGroupIdService from '../services/getAllProductAttributesByGroupIdService';
import getAllManufacturerDetailsService from '../services/getAllManufacturerDetailsService';
import getAllProductTypeService from '../services/getAllProductTypeService';
import getAllDosageService from '../services/getAllDosageService';
import addProductDetailsService from '../services/addProductDetails';
import getProductGeneralDetailsByIDService from '../services/getProductGeneralDetailsByIDService';
import useDownloadEleafletFile from '../hooks/useDownloadEleafletFile';
import getAllIvedaScheduleService from '../services/getAllIvedaScheduleService';
import getAllStorageConditionService from '../services/getAllStorageConditionService';
import useGetAllManufactureLocations from '../hooks/useGetAllManufactureLocations';

// import { getAllProductCategoryDetailsService } from '../services/getAllProductCategoryDetailsService';

const GeneralFunctions = () => {
	const location = useLocation();
	const { t } = useTranslation('productModule');
	const dispatch = useAppDispatch();
	const encProductId = useAppSelector(getEncProductId);
	const eLeafletFileName = useAppSelector(getELeafletFileName);
	const eLeafletRef = createRef<HTMLInputElement>();

	const renderCount = useRef<number>(0);

	const isProductGroupChange = useRef<number>(0);

	const isActive = useAppSelector(getProductSideBarIsActive);
	const isSubmitted = useAppSelector(getProductSideBarIsSubmited);
	const [productName, setProductName] = useState<string>('');
	const [editCommonAttributes, setEditCommonAttributes] = useState<boolean>(false);
	const [editGeneralAttributes, setEditGenaralAttributes] = useState<boolean>(false);
	const [editIVedaAttributes, setEditIVedaAttributes] = useState<boolean>(false);
	const [commonProductCode, setCommonProductCode] = useState<string>('');
	const [productDescription, setProductDescription] = useState<string>('');
	const [isEndUserScanEnabled, setIsEndUserScanEnabled] = useState<boolean>(true);
	const [encManufacturerID, setEncManufacturerID] = useState<any>('');
	const [productGroup, setProductGroup] = useState<any>('');

	const [generalLoading, setGeneralLoading] = useState<boolean>(false);
	const [generalDetailsLoading, setGeneralDetailsLoading] = useState<boolean>(false);

	const [errorState, setErrorState] = useState<Record<string, string>>({});
	const [generalErrorState, setGeneralErrorState] = useState<Record<string, string>>({});
	const [ProductGroupNameDetails, setProductGroupNameDetails] = useState<any>([]);
	const [generalFieldsData, setGeneralFieldsData] = useState<any>([]);
	const [sortedGeneralFields, setSortedGeneralFields] = useState<any>([]);
	const [manufacturerDetailsData, setManufacturerDetailsData] = useState<any>([]);
	// const [productCategoryData, setProductCategoryData] = useState<any>([]);
	const [productTypeData, setProductTypeData] = useState<any>([]);
	// const [encproductType, setEncproductType] = useState<any>('');
	const [dosageData, setDosageData] = useState<any>([]);
	// const [encDosageIVeda, setEncDosageIVeda] = useState<any>('');
	const [panelBarStatus, setPanelBarStatus] = useState<boolean>(true);
	// const [productNameIVeda, setProductNameIVeda] = useState<string>('');
	// const [productCodeIVeda, setProductCodeIVeda] = useState<any>('');
	const [mode, setMode] = useState<any>('add');
	const [genericName, setGenericName] = useState<any>('');
	// const [genericNameIVeda, setGenericNameIVeda] = useState<any>('');
	const [composition, setComposition] = useState<any>('');
	// const [compositionIVeda, setCompositionIVeda] = useState<any>('');
	const [strength, setStrength] = useState<any>('');
	// const [strengthIveda, setStrengthIveda] = useState<any>('');
	const [gs1CompanyPrefix, setGs1CompanyPrefix] = useState<any>('');
	const [addIdGenerated, setAddIdGenerated] = useState<boolean>(false);
	// const [storageConditionIVeda, setStorageConditionIVeda] = useState<any>('');
	const [storageCondition, setStorageCondition] = useState<any>('');
	const [autoSave, setAutoSave] = useState<boolean>(false);
	// const [otherDosage, setOtherDosage] = useState<string>('');
	// const [otherDosageError, setOtherDosageError] = useState<Record<string, string>>({});
	const [productStatusId, setProductStatusId] = useState<number>();
	const [isGs1WeblinkType, setIsGs1WeblinkType] =
		useState<boolean>(true); /** state for saving weblink type is GS1 or Non GS1 type */
	const [weblinkStatus, setWeblinkStatus] = useState<boolean>(false);

	const ProductAreaIconsData = useAppSelector(getProductAreaIconsData);
	const [commonAttributesInfo, setCommonAttributesInfo] = useState<string>('');
	const [generalInfo, setGeneralInfo] = useState<string>('');
	const [iVedaPropertitesInfo, setIVedaPropertitesInfo] = useState<string>('');

	// eLeaflet pdf states
	const [eLeafletBase64, setELeafletBase64] = useState<any>('');
	const [eLeafletName, setELeafletName] = useState<any>(null);
	const [eLeafletError, setELeafletError] = useState<string>('');
	const [downloadStatus, setDownloadStatus] = useState<boolean>(false);
	/**
	 * file name state for back end eleaflet file check
	 */
	const [fileName, setFileName] = useState<any>(null);

	const [generalSaveLoading, setGeneralSaveLoading] = useState<boolean>(false);

	const [sortedIVedaPropertiesFields, setSortedIVedaPropertiesFields] = useState<any>([]);
	const iVedaPropertiesStatus = useAppSelector(getIVedaPropertiesStatus);

	const [ivedaScheduleData, setIvedaScheduleData] = useState<any>([]);
	const [storageConditionData, setStorageConditionData] = useState<any>([]);

	// const [encScheduleId, setEncScheduleId] = useState<string>('');
	// const [encStorageConditionId, setEncStorageConditionId] = useState<string>('');

	const [ivedaErrorState, setIvedaErrorState] = useState<Record<string, string>>({});
	const [groupChangeLoader, setGroupChangeLoader] = useState<boolean>(false);

	const [productGroupId, setProductGroupId] = useState<string>('');
	const [initialSaveLoader, SetInitialSaveLoader] = useState<boolean>(false);

	const [manufacturerLocations, setManufacturerLocations] = useState<string[]>([]);
	const [manufacturerLocationData, setManufacturerLocationData] = useState<any>([]);

	const [productNameSuperscript, setProductNameSuperscript] = useState<any>('');

	// state for setting enable/disable serial number in common attributes page
	const [isSerialNumberEnabled, setIsSerialNumberEnabled] = useState<boolean>(true);

	// state for setting manufacturer location names in product view
	const [manufacturerLocationNames, setManufacturerLocationNames] = useState<string[]>([]);
	const [Excipients, setExcipients] = useState<string>('');


	const [iVedaData, setIVedaData] = useState({
		hsCode: '',
		// scheduled: false,
		remark: '',
		manufacturingSiteCode: '',
		// storageConditionIVeda: '',
		primaryLevelGtin: '',
		secondaryLevelGtin1: '',
		secondaryLevelGtin2: '',
		secondaryLevelGtin3: '',
		tertiaryLevelGtin: '',
		manufacturerCode: '',
		encDosageIVeda: '',
		compositionIVeda: '',
		encScheduleId: '',
		encStorageConditionId: '',
		encproductType: '',
		genericNameIVeda: '',
		strengthIveda: '',
		productCodeIVeda: '',
		productNameIVeda: '',
	});

	const [generalField, setGeneralField] = useState<any>({
		// gs1CompanyPrefix: '',
		productSecName: '',
		unitPrice: '',
		// genericName: '',
		dosage: '',
		// strength: '',
		formType: '',
		packType: '',
		// storageCondition: '',
		revisionNumber: '',
		licenseNumber: '',
		weight: '',
		ai710GermanyIFA: '',
		ai711FranceCIP: '',
		ai712SpainNationalCode: '',
		ai240AdditionalItemInfo: '',
		atPZN: '',
		beABPCode: '',
		brAnvisaRegistrationNumber: '',
		caDIN: '',
		chSwissmedic: '',
		cnSubtypeCode: '',
		dePPN: '',
		dePZN: '',
		ean13: '',
		grEOFCode: '',
		hrCroatiaNationalCode: '',
		inProductCode: '',
		internalMaterialNumber: '',
		itBollino: '',
		krKFDACode: '',
		nlKNMP: '',
		nrdNordicVNRDrugCode: '',
		ptAIMNumber: '',
		saSaudiDrugCode: '',
		usNDC442: '',
		usNDC532: '',
		usNDC541: '',
		usNDC542: '',
		ai714PortugalAIM: '',
		ai27ProductNotforSale: false,
		tNVED4: '',
		vat: '',
		siNTINCode: '',
		netContentDescription: '',
		fdaNDC11: '',
		article57Code: '',
		// treaceabilityCodeName: '',
		treacebilityCodeName: '',
		barcode: '',
		digitalCode: '',
		scanCodePrompt: false,
		callEnquiries: '',
		// composition: '',
		ukNHRN: '',
		cnProductCode: '',
		ai713BrazilAnvisa: '',
		cnPackagespec: '',
		grossWeight: '',
		netWeight: '',
		unitProductDimension: '',
		usNDCPharma: '',
		shelfLifeInDays: '',
		batchSize: '',
		fgCode: '',
		marketRegion: '',
		brandName: '',
		marketedBy: '',
		Excipents: '',
	});
	const [manufacturerLoading, setManufacturerLoading] = useState<boolean>(false);
	const [productTypeLoading, setProductTypeLoading] = useState<boolean>(false);
	const [dosageLoading, setDosageLoading] = useState<boolean>(false);
	const [storageConditionLoading, setStorageConditionLoading] = useState<boolean>(false);
	const [ivedaScheduleLoading, setIvedaScheduleLoading] = useState<boolean>(false);
	// state for IMN field in common attributes
	const [materialNumber, setMaterialNumber] = useState<string>('');
	// sets icon description data using icon ID

	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		ProductAreaIconsData?.length > 0 &&
			// eslint-disable-next-line array-callback-return
			ProductAreaIconsData?.map((item: any) => {
				if (item.encIconId === ICON_DESCRIPTION_ID.COMMON_ATTRIBUTES) {
					setCommonAttributesInfo(item?.iconDescription);
				}
				if (item.encIconId === ICON_DESCRIPTION_ID.GENERAL) {
					setGeneralInfo(item?.iconDescription);
				}
				if (item.encIconId === ICON_DESCRIPTION_ID.I_VEDA_PROPERTIES) {
					setIVedaPropertitesInfo(item?.iconDescription);
				}
			});
	}, [ProductAreaIconsData]);

	/**
	 * getting all product attributes by ID
	 * @param params id
	 */
	const getAllProductAttributesByIdData = async (params: any) => {
		const ProductAttributesByIdData = await getAllProductAttributesByGroupIdService(params);
		if (ProductAttributesByIdData) {
			setGeneralFieldsData(ProductAttributesByIdData?.output);
			setGroupChangeLoader(false);
			/**
			 * error state clearing on product group change
			 */
			setIvedaErrorState({});
			setGeneralErrorState({});
			setErrorState({});
		} else {
			setGroupChangeLoader(false);
			dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
		}
	};

	/** API call to get product details */
	const getProductGeneralDetailsById = useCallback(async (param: any) => {
		setGeneralDetailsLoading(true);
		const productGeneralDetailsResData = await getProductGeneralDetailsByIDService(param);
		if (productGeneralDetailsResData) {
			setGeneralDetailsLoading(false);
			setPanelBarStatus(false);
			setProductGroup(productGeneralDetailsResData.output.commonAttribute.encProductGroupId);
			setGeneralField(productGeneralDetailsResData.output.generalProperties);
			setIVedaData(productGeneralDetailsResData.output.ivedaProperties);
			setProductStatusId(productGeneralDetailsResData.output.statusId);
			setIsGs1WeblinkType(
				productGeneralDetailsResData?.output?.isGS1WeblinkType,
			); /** key from api to know selectd weblink type is GS1 or non GS1 type */

			setEncManufacturerID(
				productGeneralDetailsResData.output.commonAttribute.encManufacturerID,
			);
			// setEncProductCategoryId(
			// 	productGeneralDetailsResData.output.commonAttribute.encProductCategoryId,
			// );
			setProductDescription(
				productGeneralDetailsResData.output.commonAttribute.productDescription,
			);
			setIsEndUserScanEnabled(
				productGeneralDetailsResData.output.commonAttribute.isEndUserScanEnabled,
			);
			setCommonProductCode(productGeneralDetailsResData.output.commonAttribute.productCode);
			setProductName(productGeneralDetailsResData.output.commonAttribute.productName);
			// setProductNameIVeda(
			// 	productGeneralDetailsResData.output.ivedaProperties.productNameIVeda,
			// );
			// setProductCodeIVeda(
			// 	productGeneralDetailsResData.output.ivedaProperties.productCodeIVeda,
			// );
			// setEncproductType(productGeneralDetailsResData.output.ivedaProperties.encproductType);
			// setEncDosageIVeda(productGeneralDetailsResData.output.ivedaProperties.encDosageIVeda);
			getAllProductAttributesByIdData(
				productGeneralDetailsResData.output.commonAttribute.encProductGroupId,
			);

			setGenericName(productGeneralDetailsResData.output.generalProperties.genericName);
			// setGenericNameIVeda(
			// 	productGeneralDetailsResData.output.ivedaProperties.genericNameIVeda,
			// );
			setComposition(productGeneralDetailsResData.output.generalProperties.composition);
			// setCompositionIVeda(
			// 	productGeneralDetailsResData.output.ivedaProperties.compositionIVeda,
			// );
			setStrength(productGeneralDetailsResData.output.generalProperties.strength);
			// setStrengthIveda(productGeneralDetailsResData.output.ivedaProperties.strengthIveda);
			setGs1CompanyPrefix(
				productGeneralDetailsResData.output.generalProperties.gs1CompanyPrefix,
			);
			setExcipients(productGeneralDetailsResData.output.generalProperties.excipients);
			setStorageCondition(
				productGeneralDetailsResData.output.generalProperties.storageCondition,
			);
			// setStorageConditionIVeda(
			// 	productGeneralDetailsResData.output.ivedaProperties.storageConditionIVeda,
			// );
			// setOtherDosage(productGeneralDetailsResData.output.ivedaProperties.otherDosage);

			dispatch(
				setELeafletFileName(
					productGeneralDetailsResData.output.commonAttribute.eLeafletName,
				),
			);
			setELeafletName(productGeneralDetailsResData.output.commonAttribute.eLeafletName);
			/**
			 *set file name for back end eleaflet file check
			 */
			setFileName(productGeneralDetailsResData?.output?.commonAttribute?.fileName);
			// storing manufacturer gs1Prefix in redux
			dispatch(
				setManufacturerGs1Prefix(
					productGeneralDetailsResData.output.generalProperties.gs1CompanyPrefix,
				),
			);
			// sets manufacture locations
			setManufacturerLocations(
				productGeneralDetailsResData?.output?.commonAttribute?.encManufacurerLocationIds,
			);
			setProductNameSuperscript(
				productGeneralDetailsResData?.output?.commonAttribute?.productNameSuperscript
					? productGeneralDetailsResData?.output?.commonAttribute?.productNameSuperscript
					: '',
			);
			setMaterialNumber(productGeneralDetailsResData.output.commonAttribute.iMaterialNumber);
			setIsSerialNumberEnabled(
				productGeneralDetailsResData?.output?.commonAttribute?.isSerialNumberEnabled,
			);
			setManufacturerLocationNames(
				productGeneralDetailsResData?.output?.commonAttribute?.manufacturerLocationNames,
			);
			/**
			 *  updating state from APi response
			 */
			// setEncStorageConditionId(
			// 	productGeneralDetailsResData.output.ivedaProperties.encStorageConditionId,
			// );
			// setEncScheduleId(productGeneralDetailsResData.output.ivedaProperties.encScheduleId);
		} else {
			setGeneralDetailsLoading(false);
			dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// weblink status checking
	useEffect(() => {
		if (productStatusId) {
			/** to hide download weblink button when prodctStatusId = 1 and in GS1 weblink type */
			if (productStatusId === 1 || isGs1WeblinkType) {
				setWeblinkStatus(false);
			} else {
				setWeblinkStatus(true);
			}
		}
	}, [productStatusId, isGs1WeblinkType]);

	/**
	 * panel bar auto expand
	 */
	useEffect(() => {
		if (location?.state) {
			setMode(location?.state?.mode);

			if (location?.state?.mode !== 'add') {
				dispatch(setEncProductId(location?.state?.productId));
				getProductGeneralDetailsById(location?.state?.productId);
				setEditCommonAttributes(true);
				setEditGenaralAttributes(true);
				setEditIVedaAttributes(true);
				dispatch(setExpanded(['.0', '.1', '.2']));
			}
		} else {
			setMode('add');
		}
	}, [dispatch, getProductGeneralDetailsById, location.state]);

	useEffect(() => {
		if (location?.state?.mode === 'add' && encProductId) {
			if (!addIdGenerated) {
				getProductGeneralDetailsById(encProductId);
			} else {
				setAddIdGenerated(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [encProductId, getProductGeneralDetailsById]);

	/**  API call for get all product group details in dropdown */
	const getAllProductGroupNameData = async () => {
		setGeneralLoading(true);
		const productGroupData = await getAllProductGroupNameService();
		if (productGroupData?.output) {
			setProductGroupNameDetails(productGroupData?.output);
			setGeneralLoading(false);
		} else {
			setGeneralLoading(false);
			dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
		}
	};

	/** API call for get all manufacturer details in dropdown */

	const getAllManufacturerDetailsData = async () => {
		setManufacturerLoading(true);
		const ManufacturerDetails = await getAllManufacturerDetailsService();
		if (ManufacturerDetails?.output) {
			setManufacturerDetailsData(ManufacturerDetails?.output);
		} else {
			dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
		}
		setManufacturerLoading(false);
	};

	/** API call for get all product category details in dropdown */

	// const getAllProductCategoryData = async () => {
	// 	const productCategoryResData = await getAllProductCategoryDetailsService();
	// 	if (productCategoryResData?.output) {
	// 		setProductCategoryData(productCategoryResData?.output);
	// 	}
	// };

	/** API for get all product types in dropdown */

	const getAllProductTypeData = async () => {
		setProductTypeLoading(true);
		const productTypeResData = await getAllProductTypeService();
		if (productTypeResData?.output) {
			setProductTypeData(productTypeResData?.output);
		} else {
			dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
		}
		setProductTypeLoading(false);
	};

	/** API call for get all dosage data in dropdown */
	const getAllDosageData = async () => {
		setDosageLoading(true);
		const DosageResData = await getAllDosageService();
		if (DosageResData?.output) {
			setDosageData(DosageResData?.output);
		} else {
			dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
		}
		setDosageLoading(false);
	};

	/** API call for get all Iveda Schedule data in dropdown */
	const getAllIvedaScheduleData = async () => {
		setIvedaScheduleLoading(true);
		const DosageResData = await getAllIvedaScheduleService();
		if (DosageResData?.output) {
			setIvedaScheduleData(DosageResData?.output);
		} else {
			dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
		}
		setIvedaScheduleLoading(false);
	};

	/** API call for get all Storage Condition  data in dropdown */
	const getAllStorageConditionData = async () => {
		setStorageConditionLoading(true);
		const DosageResData = await getAllStorageConditionService();
		if (DosageResData?.output) {
			setStorageConditionData(DosageResData?.output);
		} else {
			dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
		}
		setStorageConditionLoading(false);
	};

	/**
	 * API calls
	 */

	useEffect(() => {
		getAllManufacturerDetailsData();
		// getAllProductCategoryData();
		getAllDosageData();
		getAllProductGroupNameData();
		getAllProductTypeData();
		getAllStorageConditionData();
		getAllIvedaScheduleData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * API call for get all manufacture locations
	 * passing selected parent manufacturer ID to get manufacturer location list
	 */
	const { manufacturerLocationRes, manufacturerLocationsLoading } =
		useGetAllManufactureLocations(encManufacturerID);

	useEffect(() => {
		/**
		 * not cheking if response is empty or not because
		 * if any empty response get in between changing the parent manufacturer
		 * the previous selected manufaturer location details will be there in state , it won't update
		 */
		setManufacturerLocationData(manufacturerLocationRes);
	}, [manufacturerLocationRes]);

	// API call for get all product group general fields

	// useEffect(() => {
	// 	if (productGroup !== '') {
	// 		getAllProductAttributesByIdData(productGroup);
	// 	}
	// }, [productGroup]);

	/**
	 * API call for add all product details
	 * @param params {commonAttribute,generalProperties,isDraft,ivedaProperties}
	 */
	const addProductDetails = async (params: any) => {
		const addProductDetailsRes = await addProductDetailsService(params);

		if (addProductDetailsRes?.output) {
			SetInitialSaveLoader(false);
			if (addProductDetailsRes?.output?.status) {
				setGeneralSaveLoading(false);
				setPanelBarStatus(false);
				if (encProductId) {
					if (!autoSave) {
						dispatch(setProductSideBarIsActive({ ...isActive, gs1: true }));
						dispatch(setProductSideBarIsSubmited({ ...isSubmitted, general: true }));
						dispatch(setProductSideBarValue('5'));
						dispatch(setMessage(t('PRODUCT_UPDATED')));
						dispatch(setNotificationType('success'));

						dispatch(setShowNotification(true));
					}
				}
				// storing encProductId in redux
				dispatch(setEncProductId(addProductDetailsRes?.output?.returnId));
			} else {
				dispatch(
					setMessage(
						t(addProductDetailsRes?.output?.resultText || 'PRODUCT_UPDATE_FAILED'),
					),
				);

				dispatch(setShowNotification(true));

				dispatch(setNotificationType('error'));
				if (!encProductId) {
					setProductGroup('');
				}
				setGeneralSaveLoading(false);
			}
		} else {
			SetInitialSaveLoader(false);
			if (!autoSave) {
				dispatch(setMessage(t('PRODUCT_UPDATE_FAILED')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType('error'));
			}
			setGeneralSaveLoading(false);
			if (!encProductId) {
				setProductGroup('');
			}
		}
		setAutoSave(false);
	};

	// initial save for common attributes to get ID
	useEffect(() => {
		if (mode === 'add') {
			const commonAttributes = {
				encProductId: '',
				productName: productName?.trim(),
				productCode: commonProductCode?.trim(),
				productDescription: productDescription?.trim(),
				encManufacturerID,
				isEndUserScanEnabled,
				isSerialNumberEnabled,
				// encProductCategoryId,
				encProductGroupId: productGroup,
				productNameSuperscript,
				iMaterialNumber: materialNumber?.trim(),
			};
			const params = {
				commonAttribute: commonAttributes,
				isDraft: true,
			};
			/**
			 * cheking condition for initial API call and to prevent multiple calls when value change through keyboard
			 */
			if (productGroupId !== '' && productGroup !== '' && !encProductId) {
				// Api call for save product
				addProductDetails(params);
				SetInitialSaveLoader(true);
			}
		}

		/**
		 * API call for get all product attributes data when dropdown value change
		 */
		if (productGroupId !== '' && productGroup !== '') {
			getAllProductAttributesByIdData(productGroup);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productGroupId, productGroup]);

	// sorted General Fields Data (general fields id to display fields)

	useEffect(() => {
		const sortedGeneralFieldsData =
			generalFieldsData &&
			generalFieldsData?.generalAttributesList?.map((item: any) => {
				return item?.generalFieldId;
			});

		setSortedGeneralFields(sortedGeneralFieldsData);

		/**
		 * i veda values checking for showing i veda section
		 */
		if (renderCount.current !== 0) {
			if (generalFieldsData && generalFieldsData?.ivedaAttributesList?.length > 0) {
				dispatch(setIVedaPropertiesStatus(true));
			} else {
				dispatch(setIVedaPropertiesStatus(false));
			}
		}
		renderCount.current = 1;

		/**
		 * sorted i veda Fields Data (i veda fields id to display fields)
		 */

		const sortedIVedaPropertiesFieldsData =
			generalFieldsData &&
			generalFieldsData?.ivedaAttributesList?.map((item: any) => {
				return item?.generalFieldId;
			});

		setSortedIVedaPropertiesFields(sortedIVedaPropertiesFieldsData);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generalFieldsData]);

	/**
	 * Auto save
	 */

	useEffect(() => {
		let interval: any = null;
		// removing auto save condition in edit mode
		if (mode === 'add' && encProductId) {
			interval = setInterval(() => {
				setAutoSave(true);
			}, AUTO_SAVE * 60000);
		}

		return () => clearInterval(interval);
	}, [encProductId, mode]);

	useEffect(() => {
		if (autoSave) {
			const sortedGeneralFieldsName =
				generalFieldsData &&
				generalFieldsData?.generalAttributesList?.map((item: any) => {
					return item?.modelProperty?.trim();
				});
			// ADD API params
			const commonAttributes = {
				encProductId,
				productName: productName?.trim(),
				productCode: commonProductCode?.trim(),
				productDescription: productDescription?.trim(),
				isEndUserScanEnabled,
				isSerialNumberEnabled,
				encManufacturerID,
				// encProductCategoryId,
				encProductGroupId: productGroup,
				eLeafletName,
				eLeafletBase64,
				fileName,
				encManufacurerLocationIds: manufacturerLocations,
				productNameSuperscript,
				iMaterialNumber: materialNumber.trim(),
			};

			const filteredgeneralFieldData: any = {};
			Object.keys(generalField)?.forEach((key) => {
				if (sortedGeneralFieldsName?.includes(key)) {
					filteredgeneralFieldData[key] = generalField[key];
				}
			});

			// states trimming for remove unwanted spaces

			const trimmedFilteredGeneralFieldData: any = {};

			Object.keys(filteredgeneralFieldData)?.forEach((item) => {
				const value = filteredgeneralFieldData[item];
				trimmedFilteredGeneralFieldData[item] =
					typeof value === 'string' ? value?.trim() : value;
			});

			const generalFieldData = {
				...trimmedFilteredGeneralFieldData,
				gs1CompanyPrefix: gs1CompanyPrefix?.trim(),
				genericName: genericName?.trim(),
				composition: composition?.trim(),
				strength: strength?.trim(),
				storageCondition: storageCondition?.trim(),
			};

			// states trimming for remove unwanted spaces

			const trimmedIVedaFieldData: any = {};

			Object.keys(iVedaData)?.forEach((item) => {
				const value = iVedaData[item];
				trimmedIVedaFieldData[item] = typeof value === 'string' ? value?.trim() : value;
			});

			const iVedaDetailsData = {
				...trimmedIVedaFieldData,
				// encproductType,
				// encDosageIVeda,
				// productNameIVeda,
				// productCodeIVeda,
				// genericNameIVeda,
				// compositionIVeda,
				// strengthIveda,
				// storageConditionIVeda,
				// encStorageConditionId,
				// encScheduleId,
			};
			const paramData = {
				commonAttribute: commonAttributes,
				generalProperties: generalFieldData,
				ivedaProperties: iVedaDetailsData,
				isDraft: true,
			};

			// errors check

			/** Api for save product */
			addProductDetails(paramData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autoSave]);

	/** function for check general fields id active or not */
	const isFieldActive = (param: any) => {
		return sortedGeneralFields?.includes(param);
	};

	/** function for check i-veda fields id active or not */
	const isIVedaPropertiesFieldActive = (param: any) => {
		return sortedIVedaPropertiesFields?.includes(param);
	};

	/**
	 * to auto update iveda state based on product group change
	 */

	useEffect(() => {
		/**
		 * condition checking for set data only when product group change
		 */
		if (isProductGroupChange?.current != 0) {
			const tempIvedaData = { ...iVedaData };

			if (isIVedaPropertiesFieldActive(66)) {
				tempIvedaData.productCodeIVeda = commonProductCode;
			} else {
				tempIvedaData.productCodeIVeda = '';
			}
			if (isIVedaPropertiesFieldActive(87)) {
				tempIvedaData.productNameIVeda = productName;
			} else {
				tempIvedaData.productNameIVeda = '';
			}

			setIVedaData({ ...tempIvedaData });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortedIVedaPropertiesFields]);

	/** Common attributes */
	const handleProductName = (e: ChangeEvent<HTMLInputElement>) => {
		setErrorState((prev) => ({ ...prev, productName: '' }));
		setProductName(e.target.value);
		/**
		 * checking Product Code field in iveda to auto-populate data in iveda
		 */
		if (isIVedaPropertiesFieldActive(87)) {
			// setProductNameIVeda(e.target.value);
			setIVedaData({ ...iVedaData, productNameIVeda: e.target.value });
		}
	};

	/**
	 * handling product code change in common attributes
	 * @param e : event
	 */
	const handleCommonProductCode = (e: ChangeEvent<HTMLInputElement>) => {
		setErrorState((prev) => ({ ...prev, commonProductCode: '' }));
		setCommonProductCode(e.target.value);
		/**
		 * checking Product Code field in iveda to auto-populate data in iveda
		 */
		if (isIVedaPropertiesFieldActive(66)) {
			// setProductCodeIVeda(e.target.value);
			setIVedaData({ ...iVedaData, productCodeIVeda: e.target.value });
		}
	};

	/**
	 * handling product description change in common attributes
	 * @param e : event
	 */
	const handleProductDescription = (e: ChangeEvent<HTMLInputElement>) => {
		setErrorState((prev) => ({ ...prev, productDescription: '' }));
		setProductDescription(e.target.value);
	};

	/**
	 * handling product description change in common attributes
	 * @param e : event
	 */
	const handleIsEndUserScanEnabled = (e: any) => {
		setErrorState((prev) => ({ ...prev, isEndUserScanEnabled: '' }));
		setIsEndUserScanEnabled(e.target.value);
	};

	/**
	 * handling product serial number change enable/disable in common attributes
	 * @param e : event
	 */
	const handleIsSerialNumberEnabled = (e: any) => {
		setIsSerialNumberEnabled(e.target.value);
	};

	/**
	 * handling manufacturer change in common attributes
	 * @param e : event
	 */
	const handleManufacturerDropDownChange = (e: any) => {
		setErrorState((prev) => ({ ...prev, encManufacturerID: '' }));
		setEncManufacturerID(e);

		/** filtering gs1Prefix from manufacturerDetailsData */
		const gs1PrefixValue =
			manufacturerDetailsData &&
			manufacturerDetailsData?.filter((item: any) => {
				return item?.encManufacturerId === e;
			});

		/**   storing manufacturer gs1Prefix in redux */
		dispatch(setManufacturerGs1Prefix(gs1PrefixValue[0]?.gs1Prefix));
		setGs1CompanyPrefix(gs1PrefixValue[0]?.gs1Prefix);

		// clearing selected manufacturer locations on manufacturer change
		setManufacturerLocations([]);
	};

	// const handleProductCategoryDropDownChange = (e: any) => {
	// 	setErrorState((prev) => ({ ...prev, encProductCategoryId: '' }));
	// 	setEncProductCategoryId(e);
	// };

	/**
	 * handling manufacturer location change in common attributes
	 * @param e : event
	 */
	const handleManufacturerLocationsChange = (e: []) => {
		setErrorState((prev) => ({ ...prev, manufacturerLocations: '' }));
		setManufacturerLocations(e);
	};

	/**
	 * handling product group change in common attributes
	 * @param e : event
	 */

	const handleProductGroupDropDownChange = (e: any) => {
		/** validation check for all required fields in common attributes */
		const commonAttributesErrors = productFormValidations(
			{
				productName,
				commonProductCode,
				productDescription,
				encManufacturerID,
				// encProductCategoryId,
			},
			{
				productName: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				commonProductCode: {
					required: true,
					maxlength: 20,
					//  alphanumericWithSpace: true
				},
				productDescription: { required: true, maxlength: 250 },
				encManufacturerID: { required: true },
				// encProductCategoryId: { required: true },
			},
		);
		setErrorState(commonAttributesErrors);

		/** errors check */
		if (Object.keys(commonAttributesErrors)?.length === 0) {
			setAddIdGenerated(true);
			setProductGroup(e);
			dispatch(setExpanded(['.0', '.1', '.2']));

			/**
			 * commenting because multiple API calls in keyboard dropdown change
			 */
			// getAllProductAttributesByIdData(e);
			// ..........

			/**
			 *  loader spinner when group change
			 */
			setGroupChangeLoader(true);
		} else {
			setProductGroup('');
		}
		/**
		 * to auto set I Veda Properties Field condition check only when product group change
		 *  */
		isProductGroupChange.current = 1;
	};

	/**
	 * On close function for dropdown change to prevent multiple API calls
	 * @param e event passing to function
	 */
	const handleProductGroupOnClose = (e: any) => {
		const dropdownValue = e?.value;
		/** validation check for all required fields in common attributes */
		const commonAttributesErrors = productFormValidations(
			{
				productName,
				commonProductCode,
				productDescription,
				encManufacturerID,
				// encProductCategoryId,
			},
			{
				productName: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				commonProductCode: {
					required: true,
					maxlength: 20,
					//  alphanumericWithSpace: true
				},
				productDescription: { required: true, maxlength: 250 },
				encManufacturerID: { required: true },
				// encProductCategoryId: { required: true },
			},
		);

		if (Object.keys(commonAttributesErrors)?.length === 0) {
			setProductGroupId(dropdownValue?.encProductgroupId);
		} else {
			setProductGroupId('');
		}
	};

	/**
	 * on open function for dropdown change to clear state
	 */
	const handleProductGroupOnOpen = () => {
		setProductGroupId('');
	};

	/**
	 * handling product code change in i-veda
	 * @param e : event
	 */

	const handleIVedaProductCode = (e: ChangeEvent<HTMLInputElement>) => {
		setIvedaErrorState((prev) => ({ ...prev, productCodeIVeda: '' }));
		// setProductCodeIVeda(e.target.value);
		setIVedaData({ ...iVedaData, productCodeIVeda: e.target.value });
	};

	/**
	 * handling product name change in i-veda
	 * @param e : event
	 */

	const handleIVedaProductName = (e: ChangeEvent<HTMLInputElement>) => {
		setIvedaErrorState((prev) => ({ ...prev, productNameIVeda: '' }));
		// setProductNameIVeda(e.target.value);
		setIVedaData({ ...iVedaData, productNameIVeda: e.target.value });
	};

	/**
	 * handling generic name change in general
	 * @param e : event
	 */
	const handleGenericNameGeneral = (e: ChangeEvent<HTMLInputElement>) => {
		setGeneralErrorState((prev) => ({ ...prev, genericName: '' }));
		setGenericName(e.target.value);
		// setGenericNameIVeda(e.target.value);
	};
	/**
	 * handling generic name change in i-veda
	 * @param e : event
	 */
	const handleGenericNameIVeda = (e: ChangeEvent<HTMLInputElement>) => {
		setIvedaErrorState((prev) => ({ ...prev, genericNameIVeda: '' }));
		// setGenericNameIVeda(e.target.value);
		setIVedaData({ ...iVedaData, genericNameIVeda: e.target.value });
	};

	/**
	 * handling composition change in general and iveda auto populate
	 * @param e : event
	 */

	const handleCompositionGeneral = (e: ChangeEvent<HTMLInputElement>) => {
		setGeneralErrorState((prev) => ({ ...prev, composition: '' }));
		setComposition(e.target.value);
		/**
		 * checking composition field in iveda to auto-populate data in iveda
		 */
		if (isIVedaPropertiesFieldActive(79)) {
			// setCompositionIVeda(e.target.value);

			setIVedaData({ ...iVedaData, compositionIVeda: e.target.value });
		}
	};

	/**
	 * handling composition change in  iveda
	 * @param e : event
	 */

	const handleCompositionIVeda = (e: ChangeEvent<HTMLInputElement>) => {
		setIvedaErrorState((prev) => ({ ...prev, compositionIVeda: '' }));
		// setCompositionIVeda(e.target.value);
		setIVedaData({ ...iVedaData, compositionIVeda: e.target.value });
	};

	/**
	 * handling strength change in general and iveda auto populate
	 * @param e : event
	 */

	const handleStrengthGeneral = (e: ChangeEvent<HTMLInputElement>) => {
		setGeneralErrorState((prev) => ({ ...prev, strength: '' }));
		setStrength(e.target.value);
		/**
		 * checking strength field in iveda to auto-populate data in iveda
		 */
		if (isIVedaPropertiesFieldActive(80)) {
			// setStrengthIveda(e.target.value);
			setIVedaData({ ...iVedaData, strengthIveda: e.target.value });
		}
	};

	/**
	 * handling strength change in i-veda
	 * @param e : event
	 */

	const handleStrengthIveda = (e: ChangeEvent<HTMLInputElement>) => {
		setIvedaErrorState((prev) => ({ ...prev, strengthIveda: '' }));
		// setStrengthIveda(e.target.value);
		setIVedaData({ ...iVedaData, strengthIveda: e.target.value });
	};

	/**
	 * handling storage condition change in general
	 * @param e : event
	 */

	const handleStorageConditionGeneral = (e: ChangeEvent<HTMLInputElement>) => {
		setGeneralErrorState((prev) => ({ ...prev, storageCondition: '' }));
		setStorageCondition(e.target.value);
		// setStorageConditionIVeda(e.target.value);
	};

	// const handleStorageConditionIveda = (e: ChangeEvent<HTMLInputElement>) => {
	// 	setIvedaErrorState((prev) => ({ ...prev, storageConditionIVeda: '' }));
	// 	setStorageConditionIVeda(e.target.value);
	// };

	/**
	 * handling General input field changes
	 * @param e : event
	 */
	const handleGeneralInputChange = (e: any) => {
		setGeneralErrorState((prev) => ({ ...prev, [e.target.name]: '' }));
		const { value } = e.target;

		setGeneralField({
			...generalField,
			[e.target.name]: value,
		});
	};

	/**
	 * handling  I-Veda Properties  input field changes
	 * @param e : event
	 */

	const handleInputChange = (e: any) => {
		setIvedaErrorState((prev) => ({ ...prev, [e.target.name]: '' }));
		const { value } = e.target;
		setIVedaData({
			...iVedaData,
			[e.target.name]: value,
		});
	};

	/**
	 * handling product type change in i-veda
	 * @param e : event
	 */

	const handleProductTypeChange = (e: any) => {
		setIvedaErrorState((prev) => ({ ...prev, encproductType: '' }));
		// setEncproductType(e);
		setIVedaData({ ...iVedaData, encproductType: e });
	};

	/**
	 * handling Schedule change in i-veda
	 * @param e : event
	 */

	const handleScheduleChange = (e: any) => {
		setIvedaErrorState((prev) => ({ ...prev, encScheduleId: '' }));
		// setEncScheduleId(e);
		setIVedaData({ ...iVedaData, encScheduleId: e });
	};

	/**
	 * handling Storage Condition change in i-veda
	 * @param e : event
	 */

	const handleStorageConditionChange = (e: any) => {
		setIvedaErrorState((prev) => ({ ...prev, encStorageConditionId: '' }));
		// setEncStorageConditionId(e);
		setIVedaData({ ...iVedaData, encStorageConditionId: e });
	};

	/**
	 * handling dosage change in i-veda
	 * @param e : event
	 */
	const handleDosageChange = (e: any) => {
		setIvedaErrorState((prev) => ({ ...prev, encDosageIVeda: '' }));
		// setEncDosageIVeda(e);
		setIVedaData({ ...iVedaData, encDosageIVeda: e });
		// if (e !== OTHER_DOSAGE_ID) {
		// 	setOtherDosage('');
		// }
	};

	// const handleOtherDosageChange = (e: any) => {
	// 	setOtherDosageError((prev) => ({ ...prev, otherDosage: '' }));
	// 	setOtherDosage(e.target.value);
	// };

	/**
	 *dowonload status change from API response
	 */
	const resetDownloadStatus = () => {
		setDownloadStatus(false);
	};

	const handleDownload = () => {
		setDownloadStatus(true);
	};

	/**
	 *dowonload e-leaflet API call
	 */
	const { eLeafletDownloadloading } = useDownloadEleafletFile(
		downloadStatus,
		encProductId,
		resetDownloadStatus,
	);

	/**
	 * trade mark or copyright add / edit function
	 */

	const updateProductNameSuperscript = (textData: any) => {
		setProductNameSuperscript(textData);
	};
	/** Common attributes */
	const handleInternalMaterialNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
		setErrorState((prev) => ({ ...prev, materialNumber: '' }));
		setMaterialNumber(e.target.value);
	};

	/**
	 *  Product save function
	 */
	const handleSave = () => {
		setELeafletError('');
		const error = productFormValidations(
			{
				productName,
				commonProductCode,
				productDescription,
				encManufacturerID,
				// encProductCategoryId,
				productGroup,
				materialNumber,
			},
			{
				productName: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				commonProductCode: {
					required: true,
					maxlength: 20,
					// alphanumericWithSpace: true
				},
				productDescription: { required: true, maxlength: 250 },
				productGroup: { required: true },
				encManufacturerID: { required: true },
				materialNumber: {
					alphanumericWithSpace: true,
					maxlength: 10,
				},
				// encProductCategoryId: { required: true },
			},
		);

		if (manufacturerLocations?.length === 0) {
			error.manufacturerLocations = t('FIELD_REQUIRED');
		}

		setErrorState(error);

		const generalFieldErrors = productFormValidations(
			{
				...generalField,
				genericName,
				composition,
				strength,
				storageCondition,
			},
			{
				// general ...
				// gs1CompanyPrefix: { required: true, numbers: true, negativeValue: true },
				productSecName: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				unitPrice: {
					required: true,
					maxlength: 10,
					// numbers: true,
					negativeValue: true,
					decimalValues: true,
				},
				genericName: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				dosage: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				strength: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				formType: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				packType: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				storageCondition: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				revisionNumber: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				licenseNumber: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				weight: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				ai710GermanyIFA: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				ai711FranceCIP: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				ai712SpainNationalCode: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true,
				},

				ai240AdditionalItemInfo: { required: true, maxlength: 250 },

				atPZN: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				beABPCode: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				brAnvisaRegistrationNumber: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true,
				},
				caDIN: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				chSwissmedic: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				cnSubtypeCode: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				dePPN: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				dePZN: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				ean13: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				grEOFCode: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				hrCroatiaNationalCode: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true,
				},
				inProductCode: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				internalMaterialNumber: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true,
				},
				itBollino: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				krKFDACode: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				nlKNMP: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				nrdNordicVNRDrugCode: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true,
				},
				ptAIMNumber: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				saSaudiDrugCode: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				usNDC442: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				usNDC532: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				usNDC541: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				usNDC542: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				ai714PortugalAIM: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				ai27ProductNotforSale: { required: true },
				tNVED4: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				vat: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				siNTINCode: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},

				netContentDescription: { required: true, maxlength: 250 },

				fdaNDC11: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				article57Code: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				treacebilityCodeName: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true,
				},
				barcode: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				digitalCode: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				scanCodePrompt: { required: true },
				callEnquiries: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				composition: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				ukNHRN: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				cnProductCode: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				ai713BrazilAnvisa: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				cnPackagespec: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				grossWeight: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				netWeight: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				unitProductDimension: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true,
				},
				usNDCPharma: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				shelfLifeInDays: {
					required: true,
					maxlength: 5,
					numbers: true,
					negativeValue: true,
				},
				batchSize: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				fgCode: {
					required: true,
					maxlength: 150,
					// alphanumericWithSpace: true
				},
				marketRegion: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				brandName: {
					required: true,
					maxlength: 150,
					//  alphanumericWithSpace: true
				},
				marketedBy: {
					required: true,
					maxlength: 250,
				},
				excipients: {
					required: true,
					maxlength: 500,
				},
			},
		);
		setGeneralErrorState(generalFieldErrors);

		const iVedaFieldErrors = productFormValidations(
			{
				...iVedaData,
				// productNameIVeda,
				// productCodeIVeda,
				// encDosageIVeda,
				// encproductType,
				// genericNameIVeda,
				// compositionIVeda,
				// strengthIveda,
				// storageConditionIVeda,
				// encStorageConditionId,
				// encScheduleId,
			},
			{
				// IVeda.....
				productNameIVeda: {
					// required: true,
					maxlength: 100,
					minlength: 2,
				},
				productCodeIVeda: {
					// required: true,
					maxlength: 14,
					minlength: 3,
				},
				// encproductType: { required: true },
				hsCode: {
					// required: true,
					maxlength: 8,
					minlength: 8,
					numbers: true,
					negativeValue: true,
				},
				// scheduled: { required: true },
				remark: {
					// required: true,
					maxlength: 200,
				},
				manufacturingSiteCode: {
					// required: true,
					maxlength: 5,
					minlength: 5,
					numbers: true,
					negativeValue: true,
				},
				genericNameIVeda: {
					// required: true,
					maxlength: 200,
					minlength: 3,
				},
				// storageConditionIVeda: {
				// 	required: true,
				// 	maxlength: 150,
				// 	// alphanumericWithSpace: true,
				// },
				primaryLevelGtin: {
					// required: true,
					minlength: 14,
					maxlength: 14,
					numbers: true,
					negativeValue: true,
				},
				secondaryLevelGtin1: {
					// required: true,
					minlength: 14,
					maxlength: 14,
					numbers: true,
					negativeValue: true,
				},
				secondaryLevelGtin2: {
					// required: true,
					minlength: 14,
					maxlength: 14,
					numbers: true,
					negativeValue: true,
				},
				secondaryLevelGtin3: {
					// required: true,
					minlength: 14,
					maxlength: 14,
					numbers: true,
					negativeValue: true,
				},
				tertiaryLevelGtin: {
					// required: true,
					minlength: 14,
					maxlength: 14,
					numbers: true,
					negativeValue: true,
				},
				compositionIVeda: {
					// required: true,
					maxlength: 500,
					minlength: 2,
				},
				strengthIveda: {
					// required: true,
					maxlength: 50,
					minlength: 1,
				},
				manufacturerCode: {
					// required: true,
					maxlength: 3,
					minlength: 3,
					numbers: true,
					negativeValue: true,
				},

				// encDosageIVeda: { required: true },
				// encStorageConditionId: {
				// 	// required: true,
				// },
				// encScheduleId: {
				// 	required: true,
				// },
			},
		);

		setIvedaErrorState(iVedaFieldErrors);

		// general not used fields validations remove
		const sortedGeneralFieldsName = generalFieldsData?.generalAttributesList?.map(
			(item: any) => {
				return item?.modelProperty?.trim();
			},
		);

		const sortedGeneralErrors: any = [];
		Object.keys(generalFieldErrors)?.filter((object1: any) => {
			// eslint-disable-next-line array-callback-return, consistent-return
			return sortedGeneralFieldsName?.some((object2: any) => {
				if (object1 === object2) {
					sortedGeneralErrors.push({ [object1]: generalFieldErrors[object1] });
					// tempArray[object1] = error[object1];
					return true;
				}
			});
		});

		/**
		 * i veda not used fields validations remove
		 */
		const sortedIvedaFieldsName =
			generalFieldsData &&
			generalFieldsData?.ivedaAttributesList?.map((item: any) => {
				return item?.modelProperty?.trim();
			});

		const sortedIvedaFieldsErrors: any = [];
		Object.keys(iVedaFieldErrors)?.filter((object1: any) => {
			// eslint-disable-next-line array-callback-return, consistent-return
			return sortedIvedaFieldsName?.some((object2: any) => {
				if (object1 === object2) {
					sortedIvedaFieldsErrors.push({ [object1]: iVedaFieldErrors[object1] });
					// tempArray[object1] = error[object1];
					return true;
				}
			});
		});

		// const otherDosageErrorMsg = productFormValidations(
		// 	{
		// 		otherDosage,
		// 	},
		// 	{
		// 		otherDosage: { required: true },
		// 	},
		// );
		// setOtherDosageError(otherDosageErrorMsg);

		// let sortedOtherDosageError: any = [];
		// if (encDosageIVeda !== OTHER_DOSAGE_ID) {
		// 	sortedOtherDosageError = {};
		// } else {
		// 	sortedOtherDosageError = { ...otherDosageErrorMsg };
		// }

		// ADD API params
		const commonAttributes = {
			encProductId,
			productName: productName?.trim(),
			productCode: commonProductCode?.trim(),
			isEndUserScanEnabled,
			isSerialNumberEnabled,
			productDescription: productDescription?.trim(),
			encManufacturerID,
			// encProductCategoryId,
			encProductGroupId: productGroup,
			eLeafletName,
			eLeafletBase64,
			fileName,
			encManufacurerLocationIds: manufacturerLocations,
			productNameSuperscript,
			iMaterialNumber: materialNumber?.trim(),
		};

		const filteredgeneralFieldData: any = {};
		Object.keys(generalField)?.forEach((key) => {
			if (sortedGeneralFieldsName?.includes(key)) {
				filteredgeneralFieldData[key] = generalField[key];
			}
		});

		// states trimming for remove unwanted spaces

		const trimmedFilteredGeneralFieldData: any = {};

		Object.keys(filteredgeneralFieldData)?.forEach((item) => {
			const value = filteredgeneralFieldData[item];
			trimmedFilteredGeneralFieldData[item] =
				typeof value === 'string' ? value?.trim() : value;
		});

		const generalFieldData = {
			...trimmedFilteredGeneralFieldData,
			gs1CompanyPrefix: gs1CompanyPrefix?.trim(),
			genericName: genericName?.trim(),
			composition: composition?.trim(),
			strength: strength?.trim(),
			storageCondition: storageCondition?.trim(),
		};

		// states trimming for remove unwanted spaces

		const trimmedIVedaFieldData: any = {};

		Object.keys(iVedaData)?.forEach((item) => {
			const value = iVedaData[item];
			trimmedIVedaFieldData[item] = typeof value === 'string' ? value?.trim() : value;
		});

		const iVedaDetailsData = {
			...trimmedIVedaFieldData,
			// encproductType,
			// encDosageIVeda,
			// productNameIVeda,
			// productCodeIVeda,
			// genericNameIVeda,
			// compositionIVeda,
			// strengthIveda,
			// storageConditionIVeda,
			// encStorageConditionId,
			// encScheduleId,
			// otherDosage,
		};

		// setting isDraft as false for validation check of product name and code in create button click

		const paramData = {
			commonAttribute: commonAttributes,
			generalProperties: generalFieldData,
			ivedaProperties: iVedaDetailsData,
			isDraft: false,
		};

		// errors check

		if (
			sortedGeneralErrors?.length === 0 &&
			sortedIvedaFieldsErrors?.length === 0 &&
			Object.keys(error)?.length === 0
			//  && Object.keys(sortedOtherDosageError).length === 0
		) {
			// Api for save product
			addProductDetails(paramData);
			setGeneralSaveLoading(true);
		} else {
			dispatch(setExpanded(['.0', '.1', '.2']));
		}
	};

	/**
	 * iveda edit button click function
	 * @param e :event
	 */
	const handleIvadeEditClick = (e: any) => {
		e.stopPropagation();
		setEditIVedaAttributes(false);
	};

	/**
	 * genaral edit button click function
	 * @param e :event
	 */

	const handleGenaralEditClick = (e: any) => {
		e.stopPropagation();
		setEditGenaralAttributes(false);
	};

	/**
	 * common attribute edit button click function
	 * @param e :event
	 */

	const handleCommonAttributeEditClick = (e: any) => {
		e.stopPropagation();
		setEditCommonAttributes(false);
	};
	const handleSelect = (event: PanelBarSelectEventArguments) => {
		if (event.expandedItems) {
			dispatch(setExpanded(event.expandedItems));
		}
	};

	/**  to convert pdf file to base 64 value */
	const getBase64 = (file: any) => {
		return new Promise((resolve) => {
			let baseURL: any = '';
			// Make new FileReader
			const reader = new FileReader();
			// Convert the file to base64 text
			reader.readAsDataURL(file);
			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				setELeafletBase64(baseURL);
				resolve(baseURL);
			};
		});
	};

	// eLeaflet pdf file change function
	const handleFileChange = useCallback(
		(event: any) => {
			if (event?.target?.files[0]) {
				const file = event?.target?.files[0];

				// Check if file is PDF
				if (!file?.type?.includes('pdf')) {
					if (eLeafletRef?.current?.value) eLeafletRef.current.value = '';
					dispatch(setMessage(t('PLEASE_UPLOAD_PDF_FILE')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
					return;
				}

				const fileSize = file.size;

				const fileSizeInMB = (fileSize / (1024 * 1024))?.toFixed();

				if (Number(fileSizeInMB) > MAX_FILE_SIZE) {
					if (eLeafletRef?.current?.value) eLeafletRef.current.value = '';
					dispatch(setMessage(t('FILE_SIZE_LIMIT_EXCEEDED')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				} else {
					setELeafletError('');
					getBase64(file);
					setELeafletName(file.name);
				}
			}
		},
		[dispatch, t, eLeafletRef],
	);

	/**
	 * E-leaflet clear funtion
	 */
	const handleELeafletFileClear = () => {
		if (eLeafletRef?.current?.value) eLeafletRef.current.value = '';
		setELeafletName(null);
		setELeafletBase64('');
		setFileName('');
	};

	return {
		productName,
		editCommonAttributes,
		editGeneralAttributes,
		editIVedaAttributes,
		commonProductCode,
		isEndUserScanEnabled,
		productDescription,
		encManufacturerID,
		// encProductCategoryId,
		productGroup,
		generalLoading,
		errorState,
		generalErrorState,
		ProductGroupNameDetails,
		manufacturerDetailsData,
		// productCategoryData,
		productTypeData,
		// encproductType,
		dosageData,
		// encDosageIVeda,
		iVedaData,
		generalField,
		panelBarStatus,
		// productNameIVeda,
		// productCodeIVeda,
		mode,
		genericName,
		// genericNameIVeda,
		composition,
		// compositionIVeda,
		strength,
		// strengthIveda,
		gs1CompanyPrefix,
		// storageConditionIVeda,
		storageCondition,
		// otherDosage,
		// otherDosageError,
		encProductId,
		weblinkStatus,
		commonAttributesInfo,
		generalInfo,
		iVedaPropertitesInfo,
		eLeafletName,
		eLeafletError,
		eLeafletDownloadloading,
		eLeafletFileName,
		generalDetailsLoading,
		generalSaveLoading,
		iVedaPropertiesStatus,
		ivedaScheduleData,
		storageConditionData,
		// encScheduleId,
		// encStorageConditionId,
		ivedaErrorState,
		groupChangeLoader,
		eLeafletRef,
		initialSaveLoader,
		manufacturerLocations,
		manufacturerLocationData,
		manufacturerLocationsLoading,
		productNameSuperscript,
		materialNumber,

		isFieldActive,
		handleProductName,
		handleCommonProductCode,
		handleProductDescription,
		handleIsEndUserScanEnabled,
		handleManufacturerDropDownChange,
		// handleProductCategoryDropDownChange,
		handleProductGroupDropDownChange,
		handleIVedaProductCode,
		handleIVedaProductName,
		handleGenericNameGeneral,
		handleGenericNameIVeda,
		handleCompositionGeneral,
		handleCompositionIVeda,
		handleStrengthGeneral,
		handleStrengthIveda,
		handleStorageConditionGeneral,
		// handleStorageConditionIveda,
		handleGeneralInputChange,
		handleInputChange,
		handleProductTypeChange,
		handleDosageChange,
		// handleOtherDosageChange,
		handleIvadeEditClick,
		handleGenaralEditClick,
		handleCommonAttributeEditClick,
		handleSelect,
		handleSave,
		handleFileChange,
		handleDownload,
		isIVedaPropertiesFieldActive,
		handleScheduleChange,
		handleStorageConditionChange,
		handleELeafletFileClear,
		manufacturerLoading,
		productTypeLoading,
		ivedaScheduleLoading,
		storageConditionLoading,
		dosageLoading,
		handleProductGroupOnClose,
		handleProductGroupOnOpen,
		handleManufacturerLocationsChange,
		updateProductNameSuperscript,
		handleInternalMaterialNumberChange,
		handleIsSerialNumberEnabled,
		isSerialNumberEnabled,
		manufacturerLocationNames,
		Excipients
	};
};

export default GeneralFunctions;
